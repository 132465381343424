<template>
  <v-container>
    <v-row justify="center">
      <div class="text-h6 text-center">Ausrüstung nach Gewicht</div>
    </v-row>
    <v-row justify="start">
      <v-col>
        <v-switch flat v-model="switch_weight" @change="filterArrayWeight" label="Inkl. Kajak"></v-switch>
      </v-col>
      <v-col>
        <v-btn @click="createWeightCategoryDataset()" x-small v-if="!weight_chart_category_active">
          Zurück zu den Kategorien
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-6">
      <v-col>
        <div class="text-body-2 text-center">{{ weight_chart_category }}</div>
      </v-col>
      <v-col>
        <div class="text-body-2 text-center">{{ weight_chart_sum }} kg</div>
      </v-col>
    </v-row>
    <v-row justify="center" class="py-2">
      <pie-chart :chartData="chartdata_pie_weight" :options="options_pie_weight" />
    </v-row>
    <v-row justify="center" class="pt-6">
      <div class="text-h6 text-center">Ausrüstung nach Preis</div>
    </v-row>
    <v-row justify="start">
      <v-col><v-switch flat v-model="switch_price" @change="filterArrayPrice" label="Inkl. Kajak"></v-switch></v-col>
      <v-col>
        <v-btn @click="createPriceCategoryDataset()" x-small v-if="!price_chart_category_active">
          Zurück zu den Kategorien
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-6">
      <v-col>
        <div class="text-body-2 text-center">{{ price_chart_category }}</div>
      </v-col>
      <v-col>
        <div class="text-body-2 text-center">{{ price_chart_sum }} €</div>
      </v-col>
    </v-row>
    <v-row justify="center" class="py-2">
      <pie-chart :chartData="chartdata_pie_price" :options="options_pie_price" />
    </v-row>
  </v-container>
</template>

<script>
import PieChart from "@/components/PieChart.vue";
import ausruestung_json from "../json/ausruestung_de.json";
export default {
  components: {
    PieChart,
  },
  data() {
    return {
      ausruestung: {},
      ausruestung_category: [],
      ausruestung_group_category: {},
      switch_weight: true,
      switch_price: true,
      weight_chart_category: "",
      weight_chart_sum: 0,
      weight_chart_category_active: true,
      price_chart_category: "",
      price_chart_sum: 0,
      price_chart_category_active: true,
      options_pie_weight: {
        parsing: {
          key: "weight",
        },
        layout: {
          padding: this.calculateChartPadding(),
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: this.calculateDatalabelsFontColor(),
            align: "end",
            anchor: this.calculateDatalabelsAnchor(),
            rotation: function (ctx) {
              const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce(function (a, b) {
                return a + b.weight;
              }, 0);
              const sum = ctx.dataset.data.reduce(function (a, b) {
                return a + b.weight;
              }, 0);

              const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex].weight / 2) / sum) * 360;
              return rotation < 180 ? rotation - 90 : rotation + 90;
            },
            display: "auto",
            font: function (context) {
              var w = context.chart.width;
              return {
                size: w < 512 ? 10 : 12,
                weight: "bold",
              };
            },
            formatter: function (value, context) {
              if (value.weight < 0.1) {
                return null;
              } else {
                return context.chart.data.labels[context.dataIndex];
              }
            },
          },
        },
        onClick: (event, activeElements) => {
          if (this.weight_chart_category_active) {
            let datasetIndex = activeElements[0].datasetIndex;
            let dataIndex = activeElements[0].index;
            let value = event.chart.data.datasets[datasetIndex].data[dataIndex];
            this.changeArray(this.chartdata_pie_weight, value, "weight");
            this.weight_chart_category = value.name;
            this.weight_chart_category_active = false;
            this.weight_chart_sum = this.sumAll(this.chartdata_pie_weight.datasets[0].data, "weight");
          } else {
            return;
          }
        },
      },
      chartdata_pie_weight: {
        labels: null,
        datasets: [{ data: [], backgroundColor: [] }],
      },
      options_pie_price: {
        parsing: {
          key: "price",
        },
        layout: {
          padding: this.calculateChartPadding(),
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            align: "end",
            anchor: this.calculateDatalabelsAnchor(),
            rotation: function (ctx) {
              const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce(function (a, b) {
                return a + b.price;
              }, 0);
              const sum = ctx.dataset.data.reduce(function (a, b) {
                return a + b.price;
              }, 0);

              const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex].price / 2) / sum) * 360;
              return rotation < 180 ? rotation - 90 : rotation + 90;
            },
            display: "auto",
            font: function (context) {
              var w = context.chart.width;
              return {
                size: w < 512 ? 10 : 12,
                weight: "bold",
              };
            },
            formatter: function (value, context) {
              if (value.price < 2) {
                return null;
              } else {
                return context.chart.data.labels[context.dataIndex];
              }
            },
          },
        },
        onClick: (event, activeElements) => {
          if (this.price_chart_category_active) {
            let datasetIndex = activeElements[0].datasetIndex;
            let dataIndex = activeElements[0].index;
            let value = event.chart.data.datasets[datasetIndex].data[dataIndex];
            this.changeArray(this.chartdata_pie_price, value, "price");
            this.price_chart_category = value.name;
            this.price_chart_category_active = false;
            this.price_chart_sum = this.sumAll(this.chartdata_pie_price.datasets[0].data, "price");
          } else {
            return;
          }
        },
      },
      chartdata_pie_price: {
        labels: [],
        datasets: [{ data: [], backgroundColor: [] }],
      },
    };
  },
  mounted() {
    // write json to vue data
    this.ausruestung = ausruestung_json;
    // group json and assign to ausruestung_group_category
    this.groupByCategory();
    // sum by category and assign to ausruestung_category
    this.sumByCategory();
    this.createWeightCategoryDataset();
    this.createPriceCategoryDataset();
  },
  methods: {
    createWeightCategoryDataset() {
      this.chartdata_pie_weight.datasets[0].data = JSON.parse(JSON.stringify(this.ausruestung_category));
      this.sortArray(this.chartdata_pie_weight.datasets[0].data, "weight");
      this.weight_chart_sum = this.sumAll(this.chartdata_pie_weight.datasets[0].data, "weight");
      this.getLabels(this.chartdata_pie_weight.datasets[0].data, this.chartdata_pie_weight.labels);
      this.randomColors(
        this.chartdata_pie_weight.datasets[0].data,
        this.chartdata_pie_weight.datasets[0].backgroundColor
      );
      this.weight_chart_category = "";
      this.weight_chart_category_active = true;
      this.filterArrayWeight();
    },
    createPriceCategoryDataset() {
      // set data, labels and colors for price chart
      this.chartdata_pie_price.datasets[0].data = JSON.parse(JSON.stringify(this.ausruestung_category));
      this.sortArray(this.chartdata_pie_price.datasets[0].data, "price");
      this.price_chart_sum = this.sumAll(this.chartdata_pie_price.datasets[0].data, "price");
      this.getLabels(this.chartdata_pie_price.datasets[0].data, this.chartdata_pie_price.labels);
      this.randomColors(
        this.chartdata_pie_price.datasets[0].data,
        this.chartdata_pie_price.datasets[0].backgroundColor
      );
      this.price_chart_category = "";
      this.price_chart_category_active = true;
      this.filterArrayPrice();
    },
    filterArrayWeight() {
      if (this.switch_weight == true) {
        this.chartdata_pie_weight.datasets[0].data = JSON.parse(JSON.stringify(this.ausruestung_category));
        let sum = this.sumAll(this.chartdata_pie_weight.datasets[0].data, "weight");
        this.weight_chart_sum = sum;
        this.sortArray(this.chartdata_pie_weight.datasets[0].data, "weight");
        this.getLabels(this.chartdata_pie_weight.datasets[0].data, this.chartdata_pie_weight.labels);
        this.randomColors(
          this.chartdata_pie_weight.datasets[0].data,
          this.chartdata_pie_weight.datasets[0].backgroundColor
        );
      } else {
        this.chartdata_pie_weight.datasets[0].data = this.ausruestung_category.filter((item) => item.name !== "Kajak");
        let sum = this.sumAll(this.chartdata_pie_weight.datasets[0].data, "weight");
        this.weight_chart_sum = sum;
        this.sortArray(this.chartdata_pie_weight.datasets[0].data, "weight");
        this.getLabels(this.chartdata_pie_weight.datasets[0].data, this.chartdata_pie_weight.labels);
        this.randomColors(
          this.chartdata_pie_weight.datasets[0].data,
          this.chartdata_pie_weight.datasets[0].backgroundColor
        );
      }
    },
    filterArrayPrice() {
      if (this.switch_price == true) {
        this.chartdata_pie_price.datasets[0].data = JSON.parse(JSON.stringify(this.ausruestung_category));
        let sum = this.sumAll(this.chartdata_pie_price.datasets[0].data, "price");
        this.price_chart_sum = sum;
        this.sortArray(this.chartdata_pie_price.datasets[0].data, "price");
        this.getLabels(this.chartdata_pie_price.datasets[0].data, this.chartdata_pie_price.labels);
        this.randomColors(
          this.chartdata_pie_price.datasets[0].data,
          this.chartdata_pie_price.datasets[0].backgroundColor
        );
      } else {
        this.chartdata_pie_price.datasets[0].data = this.ausruestung_category.filter((item) => item.name !== "Kajak");
        let sum = this.sumAll(this.chartdata_pie_price.datasets[0].data, "price");
        this.price_chart_sum = sum;
        this.sortArray(this.chartdata_pie_price.datasets[0].data, "price");
        this.getLabels(this.chartdata_pie_price.datasets[0].data, this.chartdata_pie_price.labels);
        this.randomColors(
          this.chartdata_pie_price.datasets[0].data,
          this.chartdata_pie_price.datasets[0].backgroundColor
        );
      }
    },
    /**
     * Groups the equipment items according to the category and assigns it to a data object.
     */
    groupByCategory() {
      const groupBy = (key) => (array) =>
        array.reduce((objectsByKeyValue, obj) => {
          const value = obj[key];
          objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
          return objectsByKeyValue;
        }, {});
      const groupByCategory = groupBy("category");
      this.ausruestung_group_category = groupByCategory(this.ausruestung.items);
    },
    /**
     * Sums the weight and price property of the equipment and geneerates a new array with them.
     */
    sumByCategory() {
      const keys = Object.keys(this.ausruestung_group_category);
      let array = [];
      keys.forEach((key) => {
        let totalWeight = this.ausruestung_group_category[key].reduce(function (accumulator, item) {
          return accumulator + item.weight;
        }, 0);
        let totalPrice = this.ausruestung_group_category[key].reduce(function (accumulator, item) {
          return accumulator + item.price;
        }, 0);
        array.push({
          name: key,
          price: Number(totalPrice.toFixed(2)),
          weight: Number(totalWeight.toFixed(2)),
        });
      });
      this.ausruestung_category = array;
    },
    sumAll(array, property) {
      let sum = array.reduce(function (accumulator, item) {
        return accumulator + item[property];
      }, 0);
      return sum.toFixed(0);
    },
    sortArray(array, property) {
      array.sort(function (a, b) {
        return b[property] - a[property];
      });
    },
    getLabels(array, labelsArray) {
      labelsArray.splice(0);
      array.forEach(function (item) {
        labelsArray.push(item.name);
      });
    },
    randomColors(array, backgroundColor) {
      array.forEach(() => {
        var letters = "0123456789ABCDEF".split("");
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        backgroundColor.push(color);
      });
    },
    changeArray(dataset, value, sortProperty) {
      dataset.datasets[0].data = JSON.parse(JSON.stringify(this.ausruestung_group_category[value.name]));
      this.sortArray(dataset.datasets[0].data, sortProperty);
      this.getLabels(dataset.datasets[0].data, dataset.labels);
      this.randomColors(dataset.datasets[0].data, dataset.datasets[0].backgroundColor);
    },
    calculateChartPadding() {
      return this.$vuetify.breakpoint.xs ? 20 : 150;
    },
    calculateDatalabelsFontColor() {
      return this.$vuetify.breakpoint.xs ? "white" : "black";
    },
    calculateDatalabelsAnchor() {
      return this.$vuetify.breakpoint.xs ? "center" : "end";
    },
  },
};
</script>
