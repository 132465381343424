<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="0" md="2" class="sidebar-content"></v-col>
      <v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <h1 class="font-weight-light text-center my-5">Impressum</h1>

        <strong>Paddling Beyond</strong>
        <p>Tina Mölter<br>Am Lilianhof 21<br>79289 Horben</p>
        <p>Telefon: +49 7531 60101<br>Email: <a href="mailto:info@paddlingbeyond.de">info@paddlingbeyond.de</a></p>

        <h2 class="font-weight-regular my-3">Haftungsbeschränkung</h2>
        <strong>Inhalte dieser Website</strong>
        <p>
          Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte.
        </p>
        <strong>Externe Links</strong>
        <p>
          Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle dieser externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
        </p>
        <h2 class="font-weight-regular my-3">Urheberrecht</h2>
        <p>
          Die auf dieser Website veröffentlichten Inhalte und Werke sind urheberrechtlich geschützt. Jede vom deutschen Urheberrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Autors oder Urhebers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Beiträge Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.<br>Links zur Website des Anbieters sind jederzeit willkommen und bedürfen keiner Zustimmung durch den Anbieter der Website. Die Darstellung dieser Website in fremden Frames ist nur mit Erlaubnis zulässig.
        </p>

        <h2 class="font-weight-regular my-3">Datenschutz</h2>
        <p>
          Wir nehmen den Schutz Ihrer personenbezogenen Daten ernst und beachten die Regeln der anwendbaren Datenschutzgesetze. Es ist uns wichtig, dass Sie jederzeit wissen, welche personenbezogenen Daten während Ihres Besuchs auf unserer Webseite erhoben werden und wie wir sie verwenden.<br>Personenbezogene Daten sind Einzelangaben über persönliche und sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Mit den nachfolgenden Hinweisen möchten wir Sie über die Erhebung und Verarbeitung Ihrer Daten auf unseren Webseiten informieren:
        </p>

        <strong>Protokolldaten</strong>
        <p>
          Bei jedem Zugriff eines Nutzers auf eine Seite aus diesem Webseiten-Angebot werden Daten über diesen Vorgang temporär in einer Protokolldatei gespeichert. Die temporäre Speicherung dieser sog. Server-Logdaten ist zur Erbringung des Dienstes aus technischen Gründen und danach zur Sicherstellung der Systemsicherheit erforderlich.
        </p>

        <strong>Cookies</strong>
        <p>
          Um unseren Internetauftritt benutzerfreundlich zu gestalten und optimal auf Ihre Bedürfnisse abzustimmen sowie um die erforderlichen technischen Funktionen zu ermöglichen und sicherzustellen, werden auf unserer Webseite in einigen Bereichen Cookies eingesetzt, ggfs. auch von Drittanbietern. "Cookies" sind kleine Textdateien, die lokal auf der Festplatte Ihres Endgeräts (Computer, Smartphone, Tablet) abgelegt und für einen späteren Abruf bereitgehalten werden. Nach Beendigung Ihres Besuchs und Beenden des von Ihnen verwendeten Internetbrowsers werden die meisten Cookies automatisch gelöscht (sog. Sitzungs-Cookies). Andere Cookies können für einen längeren Zeitraum auf Ihrem Endgerät verbleiben und ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies).
        </p>

        <p>
          Sie können Ihren Webbrowser so einstellen, dass er Sie beim Setzen von Cookies benachrichtigt oder Cookies generell ablehnt. Ggf. können Sie dann nicht mehr alle Funktionen unserer Webseite nutzen.<br> Über die folgenden Links können Sie sich über diese Möglichkeit für die am meisten verwendeten Browser informieren:
        </p>
        <p>
          <ul>
            <li><a target="_new" href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
            <li><a target="_new" href="http://support.mozilla.org/de/kb/cookies-informationen-websites-auf-ihrem-computer">Firefox</a></li>
            <li><a target="_new" href="http://support.google.com/chrome/bin/answer.py?hl=de&answer=95647">Google Chrome</a></li>
            <li><a target="_new" href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">Safari</a></li>
            <li><a target="_new" href="https://help.opera.com/de/latest/web-preferences/">Opera</a></li>
          </ul>
        </p>

        <strong>Google Web Fonts</strong>
        <p>
          Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </p>
        <p>
          Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.
        </p>
      </v-col>
      <v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
