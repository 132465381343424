/* eslint-disable */
<template>
  <v-list>
    <v-list-item-group active-class="no-active" mandatory>
      <v-list-item
        v-for="item in gpxCollection.slice().reverse()"
        :key="item.id"
        v-bind:id="'gpx_track_id_' + item.id"
        :ripple="false"
        inactive
        @click="$parent.zoomToGPX(item.id)"
      >
        <v-list-item-content>
          <v-card elevation="2" outlined shaped tile>
            <v-container fluid>
              <v-row align="start" no-gutters>
                <v-col cols="12"
                  ><v-card-title class="justify-center pa-0 text-h5">
                    {{ item.title }}
                    <v-icon dense class="ml-3" @click="$parent.zoomToGPX(item.id)"
                      >mdi-map-search</v-icon
                    ></v-card-title
                  ><v-card-subtitle class="text-center pb-2 grey--text pt-4">{{
                    $parent.getTrackDescription(item.date, item.description)
                  }}</v-card-subtitle></v-col
                >
                <v-col cols="6" align="left" justify="center" class="pl-4">
                  <v-row no-gutters>
                    <v-icon class="pr-2 pb-1">mdi-map-marker-distance</v-icon
                    >{{ $parent.getDistance(item) }}
                    km</v-row
                  >
                  <v-row no-gutters>
                    <v-icon v-if="item.windrichtung != null" class="pr-2 pb-1">mdi-compass-rose</v-icon
                    >{{ item.windrichtung }}</v-row
                  >
                </v-col>

                <v-col cols="6" align="left" justify="center" class="pl-4">
                  <v-row no-gutters
                    ><v-icon class="pr-2 pb-1">mdi-timer-outline</v-icon
                    >{{ $parent.getMovingTime(item) }}</v-row
                  >
                  <v-row no-gutters
                    ><v-icon class="pr-2 pb-1">{{
                      $parent.getStayTypeIcon(item.stay_type)
                    }}</v-icon
                    >{{ $parent.getStayTypeDescription(item.stay_type) }}</v-row
                  >
                </v-col>
              </v-row>
              <v-row align="start" no-gutters>
                <v-col cols="12" align="left" justify="center" class="pl-4">
                  <v-row no-gutters
                    ><v-icon class="pr-2 pb-1">{{
                      $parent.getWeatherIcon(item.weather)
                    }}</v-icon
                    >{{ $parent.getWeatherDescription(item.weather) }}</v-row
                  >
                </v-col>
              </v-row>
              <v-row v-if="item.button == 'true'" align="start" no-gutters>
                <v-col cols="12" align="left" justify="center" class="pl-4 pt-3">
                  <v-row no-gutters
                    ><v-btn @click="$parent.goToBlog(item.id)" elevation="2"
                      >Zum Logbuch</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-col cols="12" v-if="item.youtube_id">
                <div class="ma-2">
                  <youtube
                    fitParent
                    :video-id="item.youtube_id"
                    ref="youtube"
                  ></youtube>
                </div>
              </v-col>
            </v-container>
          </v-card>
        </v-list-item-content> </v-list-item></v-list-item-group
  ></v-list>
</template>

<script>
export default {
  props: {
    gpxCollection: { type: Array },
    gpx_id: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.map = this.$parent.$refs.Map.mapObject;
  },
  methods: {
    // On function call  zooms to the layer which layerID matches the current gpx_id
//    zoomToGPX(gpx_id) {
//      this.map = this.$parent.$refs.Map.mapObject;
//      let that = this;
//      this.map.eachLayer(function (layer) {
//        if (layer.layerID == gpx_id) {
//          // reset map bounds
//          that.map.invalidateSize();
//          // try catch in case gpy does´t have bounds
//          try {
//            that.map.flyToBounds(layer.getBounds());
//          } catch {
//            let lat = layer._latlng.lat;
//            let lng = layer._latlng.lng;
//            that.map.flyTo([lat, lng], 13);
//          }
//        }
//      });
//    },
//    goToBlog(id) {
//      this.$router.push({ path: "/blog/logbuch" });
//      console.log(id);
//      setTimeout(() => {
//        document.getElementById("blog-" + id).scrollIntoView({
//          behavior: "smooth",
//        });
//      }, 500);
//    },
    calcCardHeight(item) {
      if (item.youtube_id) {
        return "400px";
      } else if (item.instagram_id) {
        return "750px";
      } else {
        return "200px";
      }
    },
  },
};
</script>

<style>
.no-active::before {
  opacity: 0 !important;
}
.v-list-item:hover::before {
  opacity: 0 !important;
}
.v-list-item--active .v-list-item__content .v-card {
  background-color: #f7e6b070;
}
</style>
