<template>
  <v-carousel
    height="800px"
    v-model="init_gpx_id"
    @change="$emit('update:gpx_id', init_gpx_id)"
    class="pa-2"
    hide-delimiters
  >
    <v-carousel-item
      v-for="item in gpxCollection"
      :key="item.id"
      @click="$parent.zoomToGPX(item.id)"
    >
      <v-card :height="calcCardHeight(item)" elevation="2" outlined shaped tile>
        <v-container fluid>
          <v-row align="start" no-gutters>
            <v-col cols="12">
              <v-card-title class="justify-center pa-0">
                {{ item.title }}
                <v-icon dense class="ml-3" @click="$parent.zoomToGPX(item.id)"
                  >mdi-map-search</v-icon
                >
              </v-card-title>
              <v-card-subtitle class="text-center pb-2 grey--text pt-4">{{ $parent.getTrackDescription(item.date, item.description) }}
              </v-card-subtitle>
            </v-col>
            <v-col cols="6" align="left" justify="center" class="pl-4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">mdi-map-marker-distance</v-icon>
                    {{ $parent.getDistance(item) }} km
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon v-if="item.windrichtung != null" class="pr-2 pb-1">mdi-compass-rose</v-icon>
                    {{ item.windrichtung }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">{{
                    $parent.getWeatherIcon(item.weather) }}
                    </v-icon>{{ $parent.getWeatherDescription(item.weather) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6" align="left" justify="center" class="pl-4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">mdi-timer-outline</v-icon>
                    {{ $parent.getMovingTime(item) }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">{{
                      $parent.getStayTypeIcon(item.stay_type)
                    }}</v-icon>
                    {{ $parent.getStayTypeDescription(item.stay_type) }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-btn v-if="item.button == 'true'" small @click="$parent.goToBlog(item.id)" elevation="2">Zum Logbuch</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" align="center">
              <v-icon
                v-if="item.youtube_id || item.instagram_id"
                style="top: -25px"
                id="scroll-down-prompt"
              >
                mdi-arrow-down
              </v-icon>
            </v-col>
            <v-col cols="12" v-if="item.youtube_id">
              <div class="ma-2">
                <youtube
                  fitParent
                  :video-id="item.youtube_id"
                  ref="youtube"
                ></youtube>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: {
    gpxCollection: { type: Array },
    gpx_id: {
      type: Number,
    },
  },
  data() {
    return { init_gpx_id: this.gpx_id };
  },
  mounted() {
    this.map = this.$parent.$refs.Map.mapObject;
  },

  methods: {
    // On function call  zooms to the layer which layerID matches the current gpx_id
//    zoomToGPX(gpx_id) {
//      this.map = this.$parent.$refs.Map.mapObject;
//      let that = this;
//      this.map.eachLayer(function (layer) {
//        if (layer.layerID == gpx_id) {
//          // reset map bounds
//          that.map.invalidateSize();
//          // try catch in case gpy does´t have bounds
//          try {
//            that.map.flyToBounds(layer.getBounds());
//          } catch {
//            let lat = layer._latlng.lat;
//            let lng = layer._latlng.lng;
//            that.map.flyTo([lat, lng], 13);
//          }
//        }
//      });
//    },
//    goToBlog(id) {
//      this.$router.push({ path: "/blog/logbuch" });
//      console.log(id);
//      setTimeout(() => {
//        document.getElementById("blog-" + id).scrollIntoView({
//          behavior: "smooth",
//        });
//      }, 500);
//    },
    calcCardHeight(item) {
      if (item.youtube_id) {
        return "450px";
      } else if (item.instagram_id) {
        return "800px";
      } else {
        return "210px";
      }
    },
  },
  watch: {
    gpx_id: function (value) {
      this.init_gpx_id = value;
    },
  },
};
</script>

<style>
.cards-mobile .v-window__prev {
  top: calc(50% - 385px);
}

.cards-mobile .v-window__next {
  top: calc(50% - 385px);
}

#scroll-down-prompt {
  -webkit-animation: scrollprompt 1.5s infinite;
  animation: scrollprompt 1.5s infinite;
}
@-webkit-keyframes scrollprompt {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-0px, 20px);
    opacity: 0;
  }
}
@keyframes scrollprompt {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-0px, 20px);
    opacity: 0;
  }
}
</style>
