<template>
  <v-app-bar app color="primary" dark :value="this.AppBar" id="app-bar">
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.xsOnly"
      @click="$emit('update:drawer', !drawer)"
    ></v-app-bar-nav-icon>
    <template v-slot:extension v-if="$vuetify.breakpoint.smAndUp">
      <v-tabs :optional="true">
        <v-tab to="/"> <v-icon> mdi-home </v-icon></v-tab>
        <v-tab to="/karte">Karte</v-tab>
        <v-tab to="/kayak">Kajak</v-tab>
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-tab v-on="on">
              Ausrüstung<v-icon> mdi-chevron-down </v-icon>
            </v-tab>
          </template>
          <v-list class="grey lighten-3">
            <v-list-item to="/ausruestung/camping"> Camping </v-list-item>
            <v-list-item to="/ausruestung/navigation"> Navigation </v-list-item>
            <v-list-item to="/ausruestung/technik"> Technik </v-list-item>
            <v-list-item to="/ausruestung/bootsausruestung">
              Bootsausrüstung
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-tab v-on="on"> Reise<v-icon> mdi-chevron-down </v-icon> </v-tab>
          </template>
          <v-list class="grey lighten-3">
            <v-list-item to="/blog/vorbereitung">
              Reisevorbereitung
            </v-list-item>
            <v-list-item to="/blog/logbuch"> Logbuch </v-list-item>
          </v-list>
        </v-menu>
        <v-tab to="/statistiken">Statistiken</v-tab>
      </v-tabs>
      <v-tabs right hide-slider>
        <v-tab :ripple="false" id="no-background-hover" class="px-1">
          <v-btn
            href="https://www.youtube.com/channel/UCUH92UmEHTPcnjtx-5CEJCg"
            target="_blank"
            icon
          >
            <v-icon> mdi-youtube </v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
    },
  },
  data() {
    return {
      AppBar: false,
    };
  },
  methods: {
    showAppBar() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.AppBar = true;
      }
    },
  },
  created() {
    this.showAppBar();
  },
};
</script>

<style></style>
