<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="2" class="sidebar-content"></v-col
      ><v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <h1 class="font-weight-light text-center my-5">Navigation</h1>
        <h2 class="font-weight-regular my-3">Kompass</h2>
        <p>
          Was eignet sich zur Navigation auf See besser als ein Kompass? Genau,
          nichts! Schon die alten... naja, lassen wir das. Unsere Boote sind vor
          dem Decknetz mit Mulden für einen Einbaukompass ausgestattet und daher
          lag der Schluss nahe dort den weit verbreiteten Kugelkompass Silva 70P
          einzubauen.
        </p>

        <v-card class="mx-auto my-7" outlined>
          <v-img
            height="66vH"
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fsilva70n.jpg?alt=media&token=b042de61-3f31-4c9b-8e44-2a0ef7e08941"
          ></v-img>
          <v-card-subtitle>Eingebauter Kompass im Skagerrak.</v-card-subtitle>
        </v-card>

        <h2 class="font-weight-regular my-3">Karten</h2>
        <p>
          Nur hilft ein Kompass allein einem noch nicht um sich sicher durch das
          Wirrwarr der Schären zu schlängeln. Für den schwedischen Teil der
          Reise haben wir zwei Karten im Maßstab 1:250.000 und 1:400.000, die den
          Bereich von Gävle bis Luleå abdecken. Für Finnland haben wir eine
          Karte, die von Oulu bis Pori reicht, und zum Abschluss noch eine sehr
          genaue topografische Karte der Åland Inseln. Da wir allerdings
          zwischen den Anschlüssen der Karten einige Lücken haben, müssen wir
          hier entweder der Nase nachfahren (und im Zweifel junger Hobbit,
          sollte man immer seiner Nase folgen), oder uns selber Karten
          zusammenstellen (was uns als Geoinformatiker nicht schwerfallen
          sollte).
        </p>
        <h2 class="font-weight-regular my-3">GPS Gerät</h2>
        <p>
          Kompass und Karte sind schon gut, aber wenn mal alles schief gehen
          sollte, haben wir immer noch das Edge 820 von Garmin mit dabei. Diese
          kleine GPS-Gerät mit Touchscreen Display hatte Tina ursprünglich immer
          zum Rennradfahren dabei, aber im Grunde spricht nichts dagegen es auch
          für andere Aktivitäten einzusetzen. Neben der genauen Ortung wollen
          wir es aber vor allem auch zum Aufzeichnen unserer tatsächlichen
          Reiseroute verwenden.
        </p>

        <!-- Buttons -->
        <v-row class="mt-5 mb-1" align="center" justify="space-around">
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/camping"
          >
            <v-icon>mdi-arrow-left-bold</v-icon></v-btn
          >
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/technik"
          >
            <v-icon>mdi-arrow-right-bold</v-icon></v-btn
          >
        </v-row> </v-col
      ><v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
