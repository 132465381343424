<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="2" class="sidebar-content"></v-col
      ><v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <h1 class="font-weight-light text-center my-5">Bootsausrüstung</h1>
        <h2 class="font-weight-regular my-3">Paddelbekleidung</h2>
        <p>
          Die meisten Qutdooraktivitäten erfordern spezielle, für diese
          Aktivität optimierte Bekleidung. Während beim Fahrradfahren spezielle
          Hosen mit Sitzpolster oder Fahrradhandschuhe gefragt sein können, war
          für uns klar, dass bei unserer Tour die Paddeljacke und vor allem auch
          die Schwimmweste nicht fehlen dürfen. Letzteres dient der Sicherheit
          und darf schon aus diesem Grund nicht fehlen. Da die Schwimmweste von
          uns jeden Tag getragen wird, ist hier eine angenehme Passform mit
          genug Bewegungsfreiheit entscheidend. Zunächst hatten wir mit der
          Kaikoura oder der Pyeto von Palm aufgrund der vielen Taschen und dem
          Fach für eine Trinkblase geliebäugelt. Nachdem wir aber die Yak Xipe
          anhatten, die ebenfalls die Möglichkeit zum Transport einer Trinkblase
          hat, war schnell die Wahl klar. Für uns beide war hier das Tragegefühl
          deutlich angenehmer, da sie alle Bewegungen problemlos zulässt und
          beim Tragen im Grunde kaum bemerkt wird.
        </p>
        <p>
          Paddeljacken gibt es mit allen möglichen Ausstattungen: Mit oder ohne
          Doppelkamin, mit oder ohne Kaputze, Neoprenbündchen oder
          Latexabschluss an Handgelenk und Hals, 2,5-lagig oder sogar 4-lagig.
          Die Auswahl ist groß und wir mussten uns über die gewollten Features
          klar werden. Da wir lange Zeit unterwegs sein werden, das Wetter auch
          mal ungemütlich sein kann, war klar, dass die Wasserfestigkeit ein
          unbedingtes Kriterium sein muss. Deswegen entschieden wir uns für eine
          3-lagige Jacke mit Kaputze. Uns beiden war außerdem der Doppelkamin
          wichtig, um hier nochmal eine zusätzliche Schicht zwischen
          Spritzdecke/Trockenhose und Körper zu bekommen. Im Endeffekt hat
          Thorin nun die Palm Bora, während Tina sich für die Peak UK Adventure
          Double entschieden hat.
        </p>
        <p>
          Wenn es regnet, wird uns die Paddeljacke zuverlässig vor Nässe
          schützen. Was ist aber wenn wir kentern, die Rolle nicht funktioniert
          und wir aus dem Boot aussteigen müssen? Wir sind nass, keine Frage.
          Allerdings kann Anfang Juni die Wassertemperatur auf der Ostsee gerne
          nur ca. 10 °C betragen und bei solchen Temperaturen läuft man Gefahr
          schnell zu unterkühlen und bewegungsunfähig zu werden, sodass die
          Rettungs- und Wiedereinstiegstechnicken im schlimmsten Fall nicht mehr
          durchgeführt werden können. Ein adäquater Nässeschutz ist deshalb auch
          für die Beine notwendig. Die wohl beste, aber auch teuerste Variante
          wäre mit Sicherheit ein Trockenanzug. Aufgrund des zusätzlichen
          Gepäcks, vor allem aber aus finanzieller Sicht haben wir uns für die
          Kompromisslösung einer Trockenhose mit Doppelkamin entschieden. Die
          Kombination aus Trockenhose und Paddeljacke wird uns mit Sicherheit
          nicht komplett trocken halten, aber wir hoffen darauf, dass der
          Kälteschutz zumindest so lange hält, bis wir wieder sicher im Boot
          sind.
        </p>
        <h3 class="font-weight-regular my-2">Spritzdecken</h3>
        <p>
          Ebenfalls unverzichtbar beim Kajak fahren ist eine Spritzdecke, die
          wie eine Schürze über die Hüfte gezogen wird und das Cockpit vor
          Wassereintrag schützt. Thorin hat sich hier für die Palm Ranoake
          entschieden und Tina wollte doch lieber einen Neoprenkarmin, weshalb
          es die Peak UK Explorer wurde.
        </p>
        <h2 class="font-weight-regular my-3">Sonstiges</h2>
        <h3 class="font-weight-regular my-2">Bootswagen</h3>
        <p>
          Für den Transport der Boote an Land etwa zum Campingplatz, bei einer
          Abkürzung über eine Landenge oder falls die Zeit nicht ausreicht und
          wir eine Fähre nehmen müssen, haben wir einen Bootswagen mit dabei. Da
          unsere Boote sehr schwer sein werden haben wir uns hier für einen sehr
          stabilen mit sehr leicht laufenden Rädern entschieden. Es gibt zwar
          kleinere und leichtere, aber hier war uns Stabilität und Leichtlauf am
          wichtigsten.
        </p>
        <v-card class="mx-auto my-7" outlined>
          <v-img
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fbootswagen.JPG?alt=media&token=69810886-124e-4f9b-9e57-f8b1f12bd16f"
          ></v-img>
          <v-card-subtitle>
            Transport auf dem Bootswagen bei Regen.</v-card-subtitle
          >
        </v-card>

        <h3 class="font-weight-regular my-2">Lenzpumpe</h3>
        <p>
          Möchte man bei viel Wellengang vom Strand ablanden, kann es gut und
          gerne passieren, dass ein Schwall Wasser ins Cockpit kommt, bevor
          alles mit der Spritzdecke sicher geschützt ist. Für diesen Fall, aber
          vor allem, auch wenn wir kentern sollten, haben wir eine Lenzpumpe
          dabei. Mit dieser Pumpe ist es möglich den Großteil des Wassers im
          Cockpit bequem auszupumpen ohne, dass das Boot umgedreht werden muss.
          Für alles, was mit der Lenzpumpe nicht entfernt werden kann, haben wir
          einen Schwamm dabei, mit dem auch der letzte Tropfen aufgesaugt wird.
        </p>

        <!-- Buttons -->
        <v-row class="mt-5 mb-1" align="center" justify="space-around">
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/technik"
          >
            <v-icon>mdi-arrow-left-bold</v-icon></v-btn
          >
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/camping"
          >
            <v-icon>mdi-arrow-right-bold</v-icon></v-btn
          >
        </v-row> </v-col
      ><v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
