<template>
  <PieChart v-bind="pieChartProps" :height="800" :width="800" />
</template>

<script>
import { Chart, registerables } from "chart.js";
import { PieChart, usePieChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { defineComponent } from "@vue/composition-api";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default defineComponent({
  name: "TestChart",
  components: {
    PieChart,
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = props.chartData;
    const options = props.options;

    const { pieChartProps, pieChartRef } = usePieChart({
      chartData,
      options,
    });

    return { pieChartProps, pieChartRef };
  },
});
</script>
