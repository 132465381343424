<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="0" md="2" class="sidebar-content"></v-col>
      <v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <h1 class="font-weight-light text-center my-5">Reisevorbereitung</h1>
        <h2 class="font-weight-regular my-3">Voraussetzungen</h2>
        <p>
          Damit wir diese Reise überhaupt verwirklichen können, mussten für uns zunächst einige Vorbedingungen erfüllt
          sein. Ein wichtiger Punkt, den es zu berücksichtigen gab, war auf alle Fälle der finanzielle Aspekt dieser
          Reise. Es musste für uns möglich sein über einen Zeitraum von mehr als drei Monaten nur von unseren
          Ersparnissen zu leben, danach noch ein paar Rücklagen zu haben und natürlich musste noch das ein oder andere
          Equipment gekauft werden. Da wir jedoch einen recht sparsamen Lebensstil haben und Vollzeit arbeiten, kamen
          wir nach einigen Kalkulationen zu dem Schluss, dass der finanzielle Aspekt kein Hindernis sein wird. Auch die
          mitunter quälende Frage wie es nach der Rückkehr mit uns weitergeht hat sich auf wundersame Weise schnell und
          einfach geklärt. Anders sah es da mit unseren Fähigkeiten aus, da wir uns lange nicht sicher waren, ob wir so
          eine große Strecke in den Booten überhaupt zurücklegen können. Reale Bedingungen mussten her und so planten
          wir im Jahr 2021 das Ganze in einem Urlaub zu testen.
        </p>
        <p>
          Die Idee für diese Reise gab es schon lange, aber erst nach dem Ende unseres Studiums wurde sie konkreter.
          Thorin hat schon einige Paddeltouren mit dem Kajak und Kanadier unternommen, jedoch größtenteils auf Flüssen
          und Seen. Tina saß auch schon mal im Kajak, hatte jedoch nie Paddeltouren über einen längeren Zeitraum
          gemacht. Trotz dieser mehr oder weniger fundierten Vorkenntnisse waren wir uns nicht sicher, ob wir eine so
          lange Reise auf dem Meer auch umsetzen können. Nachdem wir endlich unsere Boote hatten, war für uns klar, dass
          wir einen Testurlaub unter möglichst realen Bedingungen machen müssen. Nach einem Blick auf die Karte haben
          wir uns für eine Route von Ratzeburg nach Stralsund entschieden. Diese bietet sowohl gemäßigte Bedingungen auf
          den Flüssen und den Bodden als auch echtes Meeresfeeling auf der Ostsee.
        </p>

        <v-card class="mx-auto my-7" outlined>
          <v-carousel hide-delimiter-background hide-delimiters height="66vH">
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
              reverse-transition="slide-transition"
              transition="fade-transition"
              transition-duration="5s"
            ></v-carousel-item>
          </v-carousel>
          <v-card-subtitle>Eindrücke aus unserem Urlaub 2021 von Ratzeburg nach Stralsund.</v-card-subtitle>
        </v-card>

        <h2 class="font-weight-regular my-3">Die Bootsnamen</h2>
        <p>
          Es ist vorgeschrieben, dass alle Wasserfahrzeuge mit einem von beiden Seiten gut leserlichen Namen versehen
          sind. Inspiriert von Tolkiens Werken nannte Tina ihr Boot Vingilot und Thorin seines Cúthalion.
        </p>
        <p>
          Vingilot: Die Schaumblüte. Benannt nach dem Schiff Earendils, mit dem er im Auftrag der zwei Geschlechter
          Richtung Valinor aufbrach, um die Valar im Kampf gegen Morgoth um Hilfe zu bitten. Da Earendil Valinor nicht
          wieder verlassen durfte, wurde Vingilot in den Ozean des Himmels erhoben, den Earendil fortan mit dem Silmaril
          auf der Stirn befuhr.
        </p>
        <p>Cúthalion: Der starke Bogen. Beiname Beleg Cúthalions, Hauptman von Doriath und Freund Túrin Turambars.</p>
        <p>
          Leider kamen die bestellten Namensfolien nicht mehr rechtzeitig zum Sommerurlaub 2021 an, sodass wir die Taufe
          erst nach unserer Rückkehr durchführen konnten.
        </p>

        <h2 class="font-weight-regular my-3">Die Reiseroute</h2>
        <p>
          Die grobe Reiseroute war für uns schon lange klar, aber der richtige Streckenverlauf musste dann doch etwas
          genauer geplant werden, vor allem um die Gesamtkilometer abschätzen zu können. Daher haben wir von dem
          gesamten Streckenverlauf ein Shapefile erstellt, wobei wir besonders bei der Überquerung von Buchten darauf
          geachtet haben das wir keine Querungen von mehr als 2 km über offenes Meer haben. Dabei kamen wir auf eine
          Gesamtstrecke von etwa 2100 km. Wenn wir uns eingewöhnt haben und wir uns auf dem Meer und in den Booten
          sicher fühlen, können wir sicher einige Buchten mit noch größerer Entfernung zum Land überqueren und dadurch
          einige Kilometer abkürzen. Die größte Herausforderung bei der Streckenführung ist mit Sicherheit die Überfahrt
          von den Ålandinseln zurück nach Schweden. Hier müssen wir einmal von Åland bis zur kleinen Insel Märket 12 km
          über offenes Meer zurücklegen und dann noch einmal 8 km von Märket bis zum schwedischen Festland.
        </p>

        <h2 class="font-weight-regular my-3">Techniktraining</h2>
        <p>
          Ohne ein bisschen Training und Üben der grundlegenden Techniken ist eine Unternehmung, wie wir sie vorhaben
          und mit unserem Wissensstand, unter Umständen etwas naiv. Deshalb haben wir nicht nur geschaut, dass wir
          regelmäßig aufs Wasser kommen, um unsere Fitness zu trainieren, sondern vor allem auch, um Rettungstechniken
          zu trainieren und Stützübungen zu erlernen, die das Kentern im besten Fall verhindern.
        </p>
        <p>
          Um einen guten Einstieg zu bekommen, war das wöchentliche Training des Trierer Kanuclubs hilfreich. Thorin hat
          aber außerdem sehr viel im Internet recherchiert und sich Videos angeschaut, in denen einzelne Techniken
          erklärt werden. Die Theorie war also schnell klar. Aber die Theorie zu kennen und diese in der Praxis zu
          beherrschen sind zwei unterschiedliche Dinge. Deswegen galt es also immer und immer wieder zu üben und hin und
          wieder auch zu kentern und dann wieder weiter zu üben.
        </p>
        <v-card class="mx-auto my-7" outlined>
          <v-img
            height="66vH"
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftechniktraining_kanten.jpg?alt=media&token=f52237fd-5566-41ae-9d37-bee9429f3ef0"
          ></v-img>
          <v-card-subtitle>Kantentraining auf der Mosel.</v-card-subtitle>
        </v-card>

        <p>
          Ein weiterer wichtiger Bestandteil unserer Vorbereitung war mit Sicherheit auch der Kurs, den wir am Ende
          unseres Urlaubs 2021 in Stralsund bei Paddelprofi absolviert haben. Hier haben wir neben Informationen zur
          richtigen Tourenplanung auch Übungen an die Hand bekommen, die dazu helfen sollen ein besseres Bootsgefühl zu
          entwickeln und den Wiedereinstieg nach einer Kenterung möglich machen.
        </p>
        <p>
          Was immer gut aussieht, aber eigentlich nicht zwingend beherrscht werden muss, ist die Eskimorolle. Wir
          wollten es uns aber trotzdem nicht nehmen lassen auch hier etwas Zeit in das Erlernen zu investieren. Die
          Meinungen im Internet, ob das ohne Weiteres selbstständig geht oder doch ein Kurs notwendig ist, waren sehr
          unterschiedlich. Deswegen legten wir einfach los und fingen an zu trainieren. Thorins Bruder, ein
          passionierter Wildwasserfahrer, hat hier auch Hilfestellung geben können und so gelang es irgendwann nach
          vielen, vielen Versuchen endlich das Wiederauftauchen aus dem Wasser. Auf jeden Fall eine schöne
          Zusatzfähigkeit, aber dennoch bleibt offen ob die Technik so weit verinnerlich ist, dass das Rollen auch im
          aufgewühlten Wasser funktioniert. Wir werden sehen...
        </p>
      </v-col>
      <v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>
<!--
<script>
export default {
  data() {
    return {};
  },
};
</script>
-->
<script>
export default {
  data() {
    return {
      items: [
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftesturlaub1.jpg?alt=media&token=582e168f-d3f9-4a41-9f76-7f374090e2f2",
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftesturlaub2.jpg?alt=media&token=ca602250-1051-44cb-857b-278e579bfd31",
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Furlaub2021_1.jpg?alt=media&token=a510dc7d-d857-42d5-911b-8f187dec5c75",
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Furlaub2021_2.jpg?alt=media&token=7e36e5b2-897a-42d4-ac17-8fa981596f06",
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Furlaub2021_4.jpg?alt=media&token=88248061-8eda-48c3-bd85-5948b30b6da7",
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Furlaub2021_6.jpg?alt=media&token=8d4ebae4-6fcc-4d7a-a6a2-c19842c96cc9",
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Furlaub2021_7.jpg?alt=media&token=82d7c5a1-b2a7-41a8-bf17-d4d42261cb9e",
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Furlaub2021_5.jpg?alt=media&token=fe86ceec-b8f3-415a-887a-1a12281f334e",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-window__prev {
  top: calc(50%);
}
.v-window__next {
  top: calc(50%);
}
</style>
