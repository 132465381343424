<template>
  <v-footer v-if="!hideFooter" color="primary lighten-1" padless>
    <v-row justify="center" no-gutters>
      <v-col align="center" cols="12" sm="2" lg="1">
        <v-btn
          small
          color="white"
          text
          rounded
          class="my-2"
          href="mailto:info@paddlingbeyond.de"
        >
          Kontakt
          <v-icon small class="mx-1"> mdi-email </v-icon>
        </v-btn>
      </v-col>
      <v-col align="center" cols="12" sm="2" lg="1">
        <v-btn
          href="https://www.youtube.com/channel/UCUH92UmEHTPcnjtx-5CEJCg"
          target="_blank"
          small
          color="white"
          text
          rounded
          class="my-2"
        >
          Youtube
          <v-icon small class="mx-1"> mdi-youtube </v-icon>
        </v-btn>
      </v-col>
      <v-col align="center" cols="12" sm="2" lg="1">
        <v-btn
          small
          color="white"
          text
          rounded
          class="my-2"
          href="/impressum"
        >
          Impressum
          <v-icon small class="mx-1 hidden"> mdi-gavel </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return { hideFooter: false };
  },
  methods: {
    showFooter() {
      if (this.$vuetify.breakpoint.xsOnly && this.$route.path == "/karte") {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
    },
  },
  created() {
    this.showFooter();
  },
  watch: {
    $route() {
      this.showFooter();
    },
  },
};
</script>

<style></style>
