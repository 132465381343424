import Vue from "vue";
import App from "./App.vue";
import VueYoutube from "vue-youtube";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import router from "./router";
import vuetify from "./plugins/vuetify";

import VueCompositionAPI from "@vue/composition-api";

const firebaseConfig = {
  apiKey: "AIzaSyA3Wg4Q1THxRHeXHP0MXbnq4ST9kiFZPB4",
  authDomain: "paddling-beyond.firebaseapp.com",
  projectId: "paddling-beyond",
  storageBucket: "paddling-beyond.appspot.com",
  messagingSenderId: "62467639764",
  appId: "1:62467639764:web:0356072d0991300f7f2e41",
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();

Vue.use(VueYoutube);

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
