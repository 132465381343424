<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="2" class="sidebar-content"></v-col
      ><v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <h1 class="font-weight-light text-center my-5">Camping</h1>
        <h2 class="font-weight-regular my-3">Schlafen</h2>
        <h3 class="font-weight-regular">Zelt</h3>
        <p>
          Beim Zelt war die Wahl einfach, da wir ein bereits oft genutztes und
          stark erprobtes Zelt haben und es uns stets ein sicherer Schutz von
          Wind und Wetter gegeben hat. Es ist das Nammatj 2GT von Hilleberg und
          es überzeugt durch seinen breiten Einsatzbereich und genug Stauraum
          für zwei Personen inklusive Gepäck. Bei allen durchgeführten Touren,
          ob zu Fuß, mit dem Fahrrad oder Kajak, hatten wir nie etwas daran
          auszusetzen. Für noch besseren Schutz vor Bodenfeuchte im Innenzelt
          haben wir zusätzlich den passenden Footprint von Hilleberg.
        </p>

        <v-card class="mx-auto my-7" outlined>
          <v-img
            height="66vH"
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fzelt.jpg?alt=media&token=8db2b944-c58d-421d-bedc-90b40f1d60e5"
          ></v-img>
          <v-card-subtitle> Das Nammatj 2GT in den Alpen.</v-card-subtitle>
        </v-card>

        <h3 class="font-weight-regular">Isomatte</h3>
        <p>
          Um den Untergrund beim Schlafen etwas weicher zu machen, haben wir
          zwei selbstaufblasende Isomatten von Therm-a-Rest dabei (Trail Lite
          und ProLite Plus). Es gibt zwar deutlich leichtere und auch welche mit
          geringerem Packmaß auf dem Markt, allerdings werden diese Isomatten,
          auch wenn sie ein Loch haben sollten, noch eine gewisse Dämmwirkung
          haben.
        </p>
        <h3 class="font-weight-regular">Schlafsack</h3>
        <p>
          Beim Schlafsack haben wir uns beide für einen Daunenschlafsack
          entschieden, da dieser eine sehr hohe Wärmeleistung bei gleichzeitig
          geringem Packmaß und Gewicht aufweist. Da gerade bei Daunenprodukten
          die Herstellung oft mit viel Tierleid einhergeht, war uns bei der Wahl
          des Herstellers wichtig, dass hier in der Lieferkette der Tierschutz
          gewährleistet ist. Aus diesem Grund haben wir uns für Mountain
          Equipment als Hersteller und das Modell Classic 500 entschieden.
        </p>
        <h2 class="font-weight-regular my-3">Kochen</h2>
        <h3 class="font-weight-regular">Trangia</h3>
        <p>
          Das Kochen während unserer Reise werden wir mit dem Trangia
          Spirituskocher machen. Die Vorteile dieses Kochers sehen wir zum einen
          in dem Brennmittel, da Spiritus eigentlich überall zu erhalten ist,
          und zum anderen in seiner Funktion als Sturmkocher. Selbst bei
          stärkerem Wind muss die warme Mahlzeit nicht ausbleiben, da das
          Gestell für den Topf gleichzeitig auch Windschutz für die Flamme ist.
          Außerdem lassen sich alle Teile perfekt ineinander verstauen, ähnlich
          einer Matruschka, sodass das Packmaß vergleichsweise gering ist und
          alles gut organisiert werden kann.
        </p>
        <v-card class="mx-auto my-7" outlined>
          <v-img
            height="66vH"
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fkocher.JPG?alt=media&token=e204bf1b-6014-47ea-8af0-0709e905be57"
          ></v-img>
          <v-card-subtitle> Couscous kochen auf dem Trangia.</v-card-subtitle>
        </v-card>
        <h3 class="font-weight-regular">Wasserfilter und Wasservorrat</h3>
        <p>
          Da wir nicht abschätzen können, wie oft wir während unserer Reise die
          Möglichkeit haben an frisches Wasser zukommen, ist es wichtig, dass
          wir zum einen immer genug dabeihaben und zum anderen auch dass wir im
          Notfall dazu in der Lage sind, sauberes Trinkwasser aus anderen
          Wasserquellen wie Flüssen oder Pfützen zu generieren. Neben einigen
          Wasserflaschen und zwei Trinkblasen für die Schwimmwesten werden wir
          auch zwei je 10 l Wassersäcke von Ortlieb als Vorratsgefäße mitnehmen.
          Und wenn alle Vorräte leer sind, wird uns hoffentlich der LifeStraw
          Gravity Water Purifier Trinkwasser liefern können.
        </p>
        <h2 class="font-weight-regular my-3">Freizeit und Spaß</h2>
        <p>
          An unseren Pausetagen oder abends, wenn das Nachtlager bereits
          aufgeschlagen ist, werden wir Zeit haben auch andere Dinge zu machen
          als nur zu paddeln. Deshalb darf Spaßausrüstung natürlich nicht
          fehlen, obgleich wir auch hier auf Größe und Gewicht achten sollten.
          Deswegen werden wir das ein oder andere Spiel mitnehmen, Musik, ein
          Rätselbuch und natürlich Lesematerial. Da Thorin für die drei bis vier
          Monate vermutlich eine kleine Bibliothek mitnehmen müsste und damit
          das ganze Boot gefüllt wäre, wird er auf einen E-Book Reader
          zurückgreifen. Tina wird mit ihrer Lesegeschwindigkeit und -ausdauer
          vermutlich gerade mal ein Buch schaffen, das sollte also noch einen
          Platz finden.
        </p>

        <!-- Buttons -->
        <v-row class="mt-5 mb-1" align="center" justify="space-around">
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/bootsausruestung"
          >
            <v-icon>mdi-arrow-left-bold</v-icon></v-btn
          >
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/navigation"
          >
            <v-icon>mdi-arrow-right-bold</v-icon></v-btn
          >
        </v-row> </v-col
      ><v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
