<template>
  <v-navigation-drawer
    width="70%"
    height="100%"
    v-model="child_drawer"
    @input="$emit('update:drawer', child_drawer)"
    app
  >
    <v-list nav dense>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        Home
      </v-list-item>
      <v-list-item to="/karte">
        <v-list-item-icon>
          <v-icon>mdi-map</v-icon>
        </v-list-item-icon>
        Karte
      </v-list-item>
      <v-list-item to="/kayak">
        <v-list-item-icon>
          <v-icon>mdi-kayaking</v-icon>
        </v-list-item-icon>
        Kajak
      </v-list-item>
      <v-list-group no-action prepend-icon="mdi-bag-personal">
        <template v-slot:activator>
          <v-list-item-content> Ausrüstung </v-list-item-content>
        </template>
        <v-list-item to="/ausruestung/camping">
          <v-list-item-content>
            <v-list-item-title>Camping</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ausruestung/navigation">
          <v-list-item-content>
            <v-list-item-title>Navigation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ausruestung/technik">
          <v-list-item-content>
            <v-list-item-title>Technik</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ausruestung/bootsausruestung">
          <v-list-item-content>
            <v-list-item-title>Bootsausrüstung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group no-action prepend-icon="mdi-notebook">
        <template v-slot:activator>
          <v-list-item-content> Reise </v-list-item-content>
        </template>
        <v-list-item to="/blog/vorbereitung">
          <v-list-item-content>
            <v-list-item-title>Reisevorbereitung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/blog/logbuch">
          <v-list-item-content>
            <v-list-item-title>Logbuch</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/statistiken">
        <v-list-item-icon>
          <v-icon>mdi-chart-pie</v-icon>
        </v-list-item-icon>
        Statistiken
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
    },
  },
  data() {
    return {
      child_drawer: this.drawer,
    };
  },
  watch: {
    drawer: function () {
      this.child_drawer = this.drawer;
    },
  },
};
</script>

<style></style>
