<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="0" md="1" lg="2" class="sidebar-content"></v-col>
			<v-col
			cols="12"
			md="10"
			lg="8"
			class="main-content rounded-lg pa-2 pa-md-6"
			>
			<h1 class="font-weight-light text-center my-5">Logbuch</h1>
			<div id="blog-0" class="pt-11">
				<h2 class="font-weight-regular my-3">Anreise - 08. Juni</h2>
				<p>
					Damit wir rechtzeitig bei der Fähre in Travemünde angekommen, sind wir bereits am Vorabend gegen 21:00 Uhr von Thorins Vater aus aufgebrochen. Aufgrund der späten Stunde, kamen wir ohne Stau oder andere Verzögerung gut durch und nun hieß es warten oder vielmehr ein bisschen Schlaf im Auto finden. Gegen 2:00 Uhr begann dann das Boarding und Thorin hat uns noch im Halbschlaf sicher auf die Fähre gebracht. Völlig unverhofft wartete eine Schlafkabine auf uns, so dass die Nacht nicht ganz so schlaflos wie befürchtet wurde. Gegen 11:15 Uhr erreichten wir Schweden und dann hieß es 800 km bis nach Hargshamn fahren. Abends um 20:30 Uhr war es endlich geschafft und unsere vom Sitzen erschöpften Körper freuten sich endlich ein paar Schritte gehen zu können. Unser Startpunkt war erreicht und nun begannen wir zu realisieren, dass unsere Reise, mit deren Vorbereitungen wir vor einem Jahr begonnen hatten, nun startet.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items0"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-1" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 1 - 09. Juni</h2>
				<p>
					Unser ganzes Equipment, welches heute Morgen noch ungeordnet im Auto lag, musste zunächst sortiert in die Boote verpackt werden. Das ist gar nicht so einfach, da möglichst auch die kleinen Räume gefüllt werden sollten und immer auf die geeignete Verteilung des Gewichtes geachtet werden muss. So kam es, dass wir fast 2 Stunden gebraucht haben, bis jedes Teil seinen Platz gefunden hat. Gegen 11:00 Uhr konnten wir endlich starten und wir wurden mit leichtem Rückenwind und Sonnenschein beschenkt. Die Landschaft ist traumhaft und so war es zu keinem Zeitpunkt schwierig einen schönen Platz für eine Pause zu finden.<br /> Alles in allem ein perfekter erster Tag.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items1"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-2" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 2 - 10. Juni</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items2"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-3" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 3 - 11. Juni</h2>
				<p>
					Als wir heute Morgen das Zelt verlassen haben, schien es ein vielversprechender Tag zu werden. Bei klarem Himmel paddelten wir los. Die ersten 18 km waren schnell bewältigt, da ein leichter Südwestwind unsere Fahrt erleichterte. Gestärkt von der Mittagspause ging es weiter. Nachdem wir eine Landzunge umrundet hatten, lief unser Kurs Richtung Süd Westen und damit in den vollen Gegenwind. Von nun an bliess uns der Wind frontal ins Gesicht und unsere Reisegeschwindigkeit reduzierte sich drastisch. Die folgenden Kilometer wurden anstrengend und kräftezehrend. Nachdem wir 27 km geschafft hatten, machten wir in einer kleinen Bucht eine kurze Pause vor dem Wind. Wir überlegten, ob wir hier nächtigen, aber nach einem kleinen Erkundungsgang war uns klar, dass in dieser Geröllhalde kein Platz für das Zelt zu finden wäre. Nach einem Blick auf die Karte beschlossen wir weitere 5 km gegen den Wind anzukämpfen um dann auf der nächsten Insel unser Lager aufzuschlagen. Totmüde ging es dann zeitig zu Bett.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items3"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-4" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 4 - 12. Juni</h2>
				<p>
					Der heutige Tag begann, wie der letzte geendet hatte: Der Südwestwind hatte keineswegs abgenommen und so paddelten wir erneut gegen den Wind an. Die ersten Kilometer mussten wir in nordwestlicher Richtung zurücklegen, so dass lediglich hohe Wellen unser Vorankommen erschwerten. Mit der Mittagspause änderte sich auch unser Kurs und wir fuhren mehr und mehr Richtung Westen. Der Wind war unerbittlich und erneut kämpften wir um jeden Meter. In der Ferne sahen wir bereits dunkle Wolken aufziehen, die nichts Gutes zu verheißen schienen. Wir wollten nun zügig Land erreichen, doch die Windböen waren teils so heftig, dass es kaum möglich war, zum nächsten Paddelschlag anzusetzen. Es schien so, als wollte uns der Wind seine ganze Kraft zeigen.
				</p>
				<p>
					Letztlich erreichten wir das Ufer und ein Blick in den Regenradar zeigte ein in wenigen Minuten ankommendes Unwetter. Schnell machten wir die Boote wetterfest, packten Kocher, Kaffee und warme Kleidung ein und flüchteten in eine kleine Hütte, die glücklicherweise offen war. Da laut Regenradar nur etwa 1 h nach dem aktuellen Unwetter das nächste folgen sollte, sahen wir uns gezwungen den Tag früher als geplant zu beenden und die Stunde zu nutzen, einen Schlafplatz zu finden. Gerade rechtzeitig vor den ersten Regentropfen befanden wir uns sicher im Zelt. Zugegebener Weise war das angekündigte Unwetter am Ende weniger schlimm als vorhergesagt und wir fühlten uns etwas veralbert angesichts unserer Vorkehrungen.
				</p>
				<p>
					Nach Sonnenschein und Wind folgt das Unwetter. Wir sind gespannt, was morgen auf uns wartet.
				</p>
			</div>
			<div id="blog-7" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 7 - 15. Juni</h2>
				<p>
					Nach nun sechs Tagen paddeln, gönnten wir uns heute einen Ruhetag. Thorin hat uns gestern Abend mit seinem Fernglas einen wunderschönen Platz auf einer Insel erspäht mit bereits improvisierter Feuerstelle und Bänken. Die Boote wurden gestern also ins Trockene gelegt und wir nutzten den heutigen Tag zum Entspannen. Nachdem wir vor zwei Tagen bereits den Wasserfilter getestet hatten, war heute das Solarpanel an der Reihe. Trotz Bewölkung ab circa 13:00 Uhr konnten wir zumindest die Hälfte der Powerbank (Gesamtkapazität 10.000 mAh) laden. Wir erkundeten unsere Insel, lasen, machten ein Feuerchen, brachten unser Equipment in Ordnung und planten die nächsten Tage. Alles in allem ein schöner und ruhiger Tag. Und jetzt sind wir auch wieder bereit für eine neue Woche paddeln.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items7"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-9" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 9 - 17. Juni</h2>
				<p>
					Da wir gestern Abend unser Zelt an der Ostseite einer Insel aufgeschlagen hatten, wollten wir heute Morgen die Gelegenheit nutzen, um uns den Sonnenaufgang anzuschauen. Das bedeutete Wecker auf 3:00 Uhr stellen um den Aufgang ein paar Minuten später beobachten zu können. Vor ein paar Tagen schon haben wir uns gefragt, ob es hier eigentlich jemals dunkel wird, da wir schon oft nachts aufwachten und der Helligkeit wegen dachten, es wäre bereits morgens und Zeit zum Aufstehen. Tatsächlich war es mitten in der Nacht. Deshalb haben wir schon zu diesem Zeitpunkt den Test gemacht und den Wecker genau zwischen Sonnenuntergang (circa 22:30 Uhr) und Sonnenaufgang (circa 3:20 Uhr) gestellt. Ergebnis war, es war nicht wirklich dunkel und doch noch recht hell. Die Stirnlampen hätten also zuhause bleiben können…</p>
				<p>
					Nach dem kleinen nächtlichen Intermezzo schliefen wir dann aber auch rasch wieder ein. Ein schöner sonniger Tag mit hohen Wellen folgte.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items9"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-11" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 11 - 19. Juni</h2>
				<p>
					Unser gestriger Tag fand ein abruptes Ende und wir mussten eine Zwangspause einlegen. Ab etwa 15:00 Uhr setzte ein starker Nordwestwind ein und vereitelte unsere Weiterfahrt. Wir studierten also die Wettervorhersage für den heutigen Tag etwas genauer. Für den gesamten Tag war ein starker Westwind (Windstärke 4-5) mit Böen bis zu Windstärke 7 vorhergesagt.
				</p>
				<p>
					**Uns war klar, dass bei diesen Bedingungen nicht viel zu machen sein wird und es sicherer ist, an Land zu bleiben. Lediglich ab 2:00 Uhr morgens sollte der Wind bis circa 8:00 Uhr etwas gemäßigter sein (Windstärke 2-3 und Böen bis 4) und so entschlossen wir uns noch am Vorabend dazu, den Wecker auf 3:00 Uhr zu stellen und die frühen Morgenstunden zum Paddeln zu nutzen. Aufgrund von Schwierigkeiten mit dem Wecker war es dann doch 1 Stunde später als geplant und so fuhren wir um 4:10 Uhr los, um die ersten Kilometer hinter uns zu bringen. Ab 7:00 Uhr war es dann wieder fast unmöglich gegen die Böen anzukommen und so fuhren wir von Bucht zu Bucht, in der Hoffnung einen Platz für den Tag zu finden. Gegen 8:00 Uhr stellten wir dann endlich das Zelt auf und holten ein wenig des zu kurz gekommenen Schlafes der letzten Nacht nach.**
				</p>
				<p>
					Selbst von Land war der Wind heftig und wir waren einmal mehr froh, heute nicht auf dem Wasser zu sein. Die Naturgewalten können beängstigend sein und es ist beruhigend, einen sicheren Platz in unserem Zelt zu haben. Morgen soll der Wind abflauen und wir hoffen auf einen schönen Tag auf dem Wasser. Ach ja, dummerweise wird unser Spiritusvorrat knapp und die nächste Einkaufsmöglichkeit ist noch etwa vier Tagesetappen entfernt. Wir müssen also etwas sparsamer den Kocher bedienen oder wie Thorin sagt: „Zur Not auf Feuer kochen.
				</p>
			</div>
			<div id="blog-13" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 13 - 21. Juni</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items13"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
				<p>
					Heute durften wir zum ersten Mal feststellen, dass auch Rückenwind einen zur Zwangspause zwingen kann. Wir hatten den ganzen Tag Südwind und die Wellen waren schon zu Beginn unseres Tages sehr kibbelig und forderten unsere volle Konzentration. Noch war uns nicht klar, dass das nur ein Vorgeschmack auf den noch kommenden Mittag war. Nach unserer Mittagspause frischte der Wind weiter auf und die Wellen wurden höher und brachen schneller. Anders als vom Wetterbericht zuvor vorhergesagt hatten wir mittlerweile Windstärke von 4-5. die Wellen beschleunigten uns teilweise auf bis zu 15 km/h, was in etwa dem dreifachen unserer normalen Geschwindigkeit entspricht. Oben auf einer Welle, wenn man quasi nur noch mit dem mittleren Teil des Bootes Kontakt zum Wasser hat, wird es schnell wackelig und wir beide befanden uns heute zum ersten Mal in der Situation, dass wir uns nicht mehr ganz so sicher in den Booten fühlten. Es war für uns höchste Zeit Schutz hinter einer Insel zu suchen. Wir hatten großes Glück, dass die Insel einen Hafen für unsere Boote hatte und wir aussteigen und abwarten konnten. Laut Wettervorhersage sollte gegen 17:00 Uhr, also etwa 2 Stunden später, der Wind drehen und nun von Nord kommen. Wir hofften, dass das zu einer Abschwächung der Wellen führen würde und wir die vor uns liegende 5 km Querung zur nächsten Insel in Angriff nehmen können. Wir harrten aus… und während wir warteten zog der Himmel sich immer mehr mit dunklen Wolken zu. Wie vom RegenRadar vorausgesagt blieb der Regen zum Glück aus und wir konnten später bei nahezu Windstille bis zur Insel Rödön queren. Gerade rechtzeitig, bevor der Nordwind stärker wurde und der Regen doch einsetzte, konnten wir bei einem nicht ganz perfekten Platz unser Zelt aufschlagen. Wir waren erschöpft und so war uns gerade alles recht.
				</p>
				<p>
					Wenn wir den heutigen Tag Revue passieren lassen, fällt uns ein Zitat aus der EOFT ein: „There's an old saying that good judgement comes from experience and experience comes from bad judgement. Sometimes this bad judgement can be pretty horrific.”
				</p>
			</div>
			<div  class="pt-11">
				<h2 class="font-weight-regular my-3">Die ersten zwei Wochen</h2>
				<div class="my-7">
					<youtube fitParent video-id="7xSbWyGKkEc" ref="youtube"></youtube>
				</div>
			</div>
			<div id="blog-15" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 15 - 23. Juni</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items15"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
				<p>
					Nachdem wir gestern Abend in Härnösand angekommen sind, ist heute unser Ruhetag. Nach langem Ausschlafen sind wir erst mal in die Stadt gelaufen zum Einkaufen. Nachdem wir schwer beladen mit Einkäufen zurück waren, haben wir erstmal gefrühstückt und Kaffee getrunken. Anschließend wurde komfortabel in einer Waschmaschine Wäsche gewaschen und wir haben unseren Einkauf verstaut. Nach mehreren vergeblichen Versuchen, ein Video zu schneiden hat Thorin es endlich mehr schlecht als recht hinbekommen. Nach diesem Fiasko sind wir erst mal wieder in die Stadt gelaufen, um Thorin ein Stark-Öl (schwedisches Starkbier) und Tina ein Cider aus dem Systembolaget (Alkoholgeschäft) zu holen.
				</p>
				<p>
					Alles in allem kein ganz ruhiger aber dafür ein sehr erfolgreicher Tag, nach dem wir wieder frisch gestärkt und mit Proviant eingedeckt in See stechen können.
				</p>
			</div>
			<div id="blog-18" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 18 - 26. Juni</h2>
				<p>
					Unser heutiger Paddeltag begann etwas später als gewöhnlich. Wir waren nämlich gestern Abend auf Mjältön, der mit 236 m höchsten Insel Schwedens, angekommen. Einen Aufstieg wollten wir uns nicht nehmen lassen und so bauten wir heute Morgen unser Zelt ab und begannen erst einmal mit dem Marsch auf den Gipfel der Insel. Mit der Entfernung zum Wasser stieg auch die Lufttemperatur deutlich an und so kamen wir ordentlich ins Schwitzen, was sonst nur selten der Fall ist. Der Ausblick war fantastisch und der Aufstieg hatte sich mehr als gelohnt. Danach wieder die 236 m bergab, Frühstückspause und nun konnte das Paddeln weitergehen. Die folgenden 28 km paddelten wir hauptsächlich ohne Pause. Wir landeten nur hin und wieder kurz an, um aus den Booten raus zu kommen. Aber nach wenigen Minuten waren wir auch schon wieder auf dem Wasser.
				</p>
				<p>
					An einem anderen Ort hätten wir heute vermutlich nicht das Land verlassen, da uns ein starker Wind aus Süd hohe Wellen bescherte. Da wir aber mehr oder weniger regelmäßig von Inseln umgeben waren, wurden diese teilweise etwas abgeschwächt. Dennoch forderten einzelne Passagen unsere volle Konzentration und so waren wir froh, am Abend wieder sicher ohne Kennterung an unserem Zeltplatz angekommen zu sein.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items18_1"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
				<p>
					Das Wetter war traumhaft. Es war warm und so waren die Gegebenheiten perfekt um mit den vollen Booten eine Rolle zu probieren. Durch das Gewicht der vollen Bote war es gar nicht so einfach, diese zum Kentern zu bringen. Dies bemerkten wir schon beim probieren der Stütze, die sehr einfach ging. Anschließend wollten wir die Boote ganz zum Kentern bringen, was nur mit viel Mühe gelang. Die anschließende Rolle war da hingegen sehr einfach und schwupp-die-wupps waren wir wieder über Wasser.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items18_2"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div  class="pt-11">
				<h2 class="font-weight-regular my-3">Höga Kusten - Robertsfors</h2>
				<div class="my-7">
					<youtube fitParent video-id="nxBfOp4XtO8" ref="youtube"></youtube>
				</div>
				</div>
				<div id="blog-22" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 22 - 30. Juni</h2>
				<p>
					Obwohl heute eigentlich ein Tag wie jeder andere war, war es doch heute Abend besonders für uns, als wir unser Lager ein wenig nördlich vom Umeå aufgeschlagen haben. Umeå war für uns beide wie ein kleineres Zwischenziel, da es bereits in Nordschweden liegt und wir mit dem Erreichen vermutlich schon etwa ein Viertel der gesamten Strecke zurückgelegt haben.
				</p>
				<p>
					Bei nahezu Windstille kamen wir heute gut voran, obwohl nach mittlerweile schon wieder sieben Tagen auf dem Wasser, ohne einen Ruhetag, die Erschöpfung doch früher eintritt als noch vor ein paar Tagen. Aber für das Wochenende sind hohe Windgeschwindigkeiten bis zu Windstärke 7 vorhergesagt und wir wollen jetzt noch die ruhigen Tage zum Vorwärtskommen nutzen.
				</p>
			</div>
			<div id="blog-26" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 26 - 04. Juli</h2>
				<p>
					Nach unserem Ruhetag fühlten wir uns motiviert, den heutigen Tag bereits um 5:15 Uhr zu beginnen. Aber viel mehr als unsere Motivation, zwang uns die Windvorhersage zu einem frühen Aufstehen. Ab 11:00 Uhr waren ein starker Südwind, Windstärke 4 und Böen bis 6 vorhergesagt. Da sich unsere Essensvorräte stark dem Ende entgegen neigten, mussten wir heute unbedingt nah an die 30 km herankommen. Andernfalls würden wir es bis Mittwoch nicht nach Skelleftehamn schaffen, unserer nächsten Einkaufsmöglichkeit.
				</p>
				<p>
					Diese Morgenstimmung hat schon etwas Besonderes, obgleich wir ziemlich bald nicht mehr viel davon mitbekommen haben, da wir uns bereits nach nur wenigen Metern in dichtem Nebel befanden. Zum ersten Mal mussten wir nun wirklich auf Kompass und Karte vertrauen, da wir selbst das nahe Ufer nicht mehr sehen konnten. Nur das Tosen der Brandung zeigte uns, dass das Land zumindest in Hörweite war. Nachdem man einige Kilometer gefühlt ins Nichts gepaddelt ist, waren wir schon sehr überrascht, als plötzlich wieder die Silhouetten von Bäumen zu erkennen waren.
				</p>
				<p>
					Da wir möglichst viel Kilometer vor 11:00 Uhr gefahren sein wollten, dem Zeitpunkt ab dem der Wind stark auffrischen sollte, waren wir etwa 4 Stunden am Stück auf dem Wasser. Das GPS-Gerät zeigte dann die 28,9 km an, als wir an eine Insel für die Mittagspause anlandeten. Alles was jetzt noch kam war Bonus. Und da wir uns nach unserer Pause noch immer ausgeruht fühlten (es war ja auch noch früh) und die Windverhältnisse noch nicht so schlimm waren wie erwartet, paddelten wir einfach weiter. Nach weiteren 2 Stunden im Boot suchten wir uns einen Platz für den Nachmittag und Abend und ruhten uns für den nächsten Tag aus.
				</p>
			</div>
			<div id="blog-27" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 27 - 05. Juli</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items27"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-28" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 28 - 06. Juli</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items28"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-29" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 29 - 07. Juli</h2>
				<p>
					Als wir heute Morgen um 8:30 Uhr losfahren wollten, waren wir noch recht unentschlossen, ob wir unsere Paddeljacken anziehen sollten. Dunkle Wolken waren am Himmel zu sehen, aber das Regenradar sagte, dass es nur leichte Schauer geben sollte und wir nur am Rande davon betroffen sein sollten. Letztenendes vertrauten wir eher dem was wir sahen, als der Vorhersage. Und wir taten gut daran. Nach nicht einmal einer halben Stunde befanden wir uns in einer dicken Regenfront, die so schnell nicht wieder aufhörte. Obwohl unsere Bekleidung wirklich sehr gut ist, hält sie auch stundenlangem Dauerregen nicht stand. Nach fast 23 km und mehr als 3 Stunden paddeln im Regen, gaben wir uns geschlagen und suchten Schutz auf einer Insel.
				</p>
				<p>
					Es war mittlerweile 13:00 Uhr und unsere letzte Mahlzeit war am Abend zuvor. Wir hatten also Hunger und wollten gerne eine Pause machen und vor allem raus aus den nassen Sachen. Da die einzige Möglichkeit an Land im Trockenen zu sein darin bestand das Zelt aufzubauen, entschlossen wir uns kurzerhand dies zu tun. Nun folgten Phasen von kleinen Regenschauern gefolgt von Sonnenschein. Die Sachen bei diesen Bedingungen zu trocknen war wirklich schwierig, da wir abwechselnd immer wieder die Sachen auf- und abhängen mussten.
				</p>
				<p>
					Nachdem der weitere Tag keine besseren Bedingungen versprach, entschlossen wir uns den restlichen Tag über im Zelt zu bleiben. Nicht schön, aber vielleicht heute notwendig.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items29"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-32" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 32 - 10. Juli</h2>
				<p>
					Den heutigen Vormittag verbrachten wir im Zelt, da es, entgegen aller Vorhersagen, heute Nacht um circa 3:00 Uhr angefangen hat zu regnen. Der Regen hörte bis mittags auch nur sporadisch auf und da unter diesen Bedingungen ein Aufbrechen bereits nass beginnen würde, entschieden wir uns erstmal abzuwarten. Das war auch nicht weiter schlimm, da wir bis nach Luleå nur etwa 20 km zu fahren hatten. Ab 14:00 Uhr konnten wir dann aber endlich los und nahmen die 20 km in Angriff.
				</p>
				<p>
					Bereits am Vorabend haben wir uns mit Hilfe eines Satellitenbildes einen Platz für unser Lager ausgesucht, da es in einer Stadt gar nicht so einfach ist, einen geeigneten Platz zu finden. Wir mussten am nächsten Tag in die Stadt kommen, um einzukaufen und eine neue Isomatte für Thorin zu besorgen, weshalb die Nähe zu einer Bushaltestelle gut wäre. Letztenendes fiel unsere Wahl auf einen Strand in der Nähe des Flughafens. Das hat den Vorteil, dass wir die Infrastruktur des Flughafens auch zum Nachfüllen unserer Wasserspeicher nutzen können.
				</p>
				<p>
					Den morgigen Vormittag werden wir in Luleå verbringen und danach vermutlich noch einige Kilometer aus der Stadt raus fahren und mit dem Passieren von Luleå ändert sich nun auch unser Kurs von Nord auf Ost.
				</p>
			</div>
			<div  class="pt-11">
				<h2 class="font-weight-regular my-3">Robertsfors - Luleå</h2>
				<div class="my-7">
					<youtube fitParent video-id="v-6XjSZdhSc" ref="youtube"></youtube>
				</div>
			</div>
			<div id="blog-34" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 34 - 12. Juli</h2>
				<p>
					Unsere Tage sind meistens alle sehr ähnlich in ihrem Ablauf, sofern uns die Natur keinen anderen Rhythmus aufzwingt. In der Regel stehen wir gegen halb acht auf, bauen dann das Lager ab, putzen die Zähne, cremen uns mit Sonnencreme ein und beladen die Boote. Alles in Allem brauchen wir dafür meistens etwa 1 Stunde. Dann geht es los, die ersten 8-12 km für den Tag zu fahren. An manchen Tagen werden daraus auch schnell mal 15 km oder - unser aktueller Rekord - fast 30 km. Das machen wir aber sehr ungern und nur, wenn die Gegebenheiten uns dazu zwingen.
				</p>
				<p>
					In der Regel gibt es aber nach 1,5-2 h unsere, schon zu Beginn des Tages, ersehnte Frühstücks-/Mittagspause. Zwischendurch und absolut unentbehrlich sind die kleinen Pausen auf dem Wasser, bei denen es meistens einen Keks oder Nüsse und Trockenobst gibt. Kurz nach einem Wocheneinkauf gibt es bei der Mittagspause meistens noch Brot mit Käse und Gurke/Zucchini, je weiter aber der letzte Einkauf zurück liegt, desto mehr müssen wir auf Polenta oder Müsli (mit Wasser) umsteigen. Bei der Mittsgspause verweilen wir oft ein bis 2 Stunden und wenn Süßwasser in der Nähe ist werden die Wasservorräte aufgefüllt. Bei Sonnenschein legen wir meistens auch noch das Solarpanel aus, um auch diese Speicher wieder aufzuladen. Zurück in den Booten nehmen wir die nächsten circa 10 km in Angriff. Dann noch mal ein kurzer Landgang, um die Beine zu vertreten und die Wasservorräte wieder los zu werden. Diese Pause ist meistens nur recht kurz und dann geht es schon wieder weiter, um den Endspurt für den Tag anzutreten.
				</p>
				<p>
					Oft paddeln wir schweigend nebeneinander oder voreinander her. Jeder in seiner Musik und seinen Gedanken vertieft. Manchmal aber, wenn das Meer ruhig ist, quatschen wir auch ein wenig.
				</p>
				<p>
					Meist erreichen wir dann gegen 17:30 Uhr bis 18:00 Uhr unseren Schlafplatz. Danach folgt Aufgabenteilung: Thorin eruiert meist unsere Umgebung und sucht nach einem geeigneten Platz für das Zelt. Er hat hier deutlich mehr Erfahrung als Tina und kann gute Plätze schnell ausfindig machen. Selbst an schwierigen Orten findet er doch immer noch eine kleine Lücke, in die wir uns quetschen können. Währenddessen kümmert sich Tina um das Abendessen. Damit wir am nächsten Tag früher starten können, ist damit auch immer das Auffüllen der Trinkblasen und das Herrichten des Snackbeutels verbunden.
				</p>
				<p>
					Wenn das Lager aufgeschlagen ist, gönnen wir unseren Körpern eine Erfrischung im Wasser. Das ist das schöne am Paddeln: Es fehlt einem nie an Wasser um sich zu waschen.
				</p>
				<p>
					Nun geht es aber wirklich ans Kochen. Unser Abendessen ist meistens sehr ähnlich, nur die Grundzutaten variieren: d.h. es gibt eigentlich immer Zwiebeln, Knoblauch und Gemüse (entweder Zucchini und Karotten oder wenn es an frischen Zutaten mangelt auch mal Gemüse aus der Dose) und dazu Couscous, Reis, Linsen oder Nudeln. Die wichtigste Geheimzutat ist aber Gemüsebrühe. Leider mussten wir schon bei unserem ersten Einkauf feststellen, dass Gemüsebrühe nicht zum Standardsortiment der Supermärkte hier gehört. Das heißt, bald gibt es nur noch Brühwürfel.
				</p>
				<p>
					Nach dem Essen ist es meistens schon 20 Uhr oder 20:30 Uhr und damit auch schon fast Zeit fürs Schlafen gehen. Wir packen also unsere Sachen zusammen, machen den Abwasch, putzen die Zähne und machen die Boote fertig für die Nacht. Da wir abends oft kaputt vom Tag sind, spielen wir meistens nur noch kurz das ein oder andere Spiel oder lesen noch ein paar Seiten. Spätestens ab 22 Uhr fallen uns dann die Augen müde zu.
				</p>
				<p>
					Tage, an denen wir einkaufen müssen, bringen uns etwas aus dem Rhythmus, da die Supermärkte oft nicht in Wassernähe liegen und meist mit einem Umweg von bis zu 5 km verbunden sind. Deshalb versuchen wir nur etwa einmal die Woche einkaufen zu gehen. Das mit dem Einkaufen ist nämlich so eine Sache: Einerseits ist es toll wieder alle Sachen haben zu können, andererseits werden dadurch die Tage meist lang und die Boote wieder schwer.
				</p>
				<p>
					So ist doch irgendwie jeder Tag wie der andere, doch gleichzeitig auch wieder ganz anders.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items34"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-35" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 35 - 13. Juli</h2>
				<p>
					Heute haben wir uns einen halben Ruhetag gegönnt, haben ausgeschlafen und sind erst gegen halb eins gestartet. Trotz dieses späten Starts haben wir immerhin fast 30 km geschafft, nicht zuletzt Dank Thorins genialen Abkürzungen. Eine davon führte zwischen zwei Inseln hindurch, welche auf dem Satellitenbild zwar sehr nah beieinander lagen aber eine Durchfahrt trotzdem möglich schien. Diese entpuppte sich zunächst als schmaler Kanal, welcher jedoch immer schmaler wurde. Am Ende mussten wir einen sehr engen Wasserlauf durch Binsen und Schilf folgen, wobei wir uns teilweise mit den Händen durch die Binsen ziehen mussten. Zum Glück konnten wir dennoch in den Booten bleiben und mussten nicht schieben, denn hier hätten uns sofort die Mücken und Bremsen aufgefressen.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items35"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-36" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 36 - 14. Juli</h2>
				<p>
					Heute war in vielerlei Hinsicht ein besonderer Tag. Und auch ein Tag, an dem wir am Abend zurück blicken und feststellen, dass er ganz anders wurde als erwartet.
				</p>
				<p>
					Die erste Besonderheit gab es bereits nach 4,8 km: Diese Strecke sorgte dafür, dass wir nun eine zurückgelegte Gesamtdistanz von 1000 km vervollständigt haben. Da die Bedingungen zu diesem Zeitpunkt nicht optimal für eine Pause auf dem Wasser war, gab es nur schnell einen Keks und schon ging es weiter.
				</p>
				<p>
					Als Tagesziel wollten wir heute in Haparanda/Tornio ankommen und hier unseren morgigen Ruhetag auf einem Campingplatz verbringen. Der Weg dorthin führte unter einer Brücke zur Insel Seskarö hindurch, welche uns unerwartet vor die erste Herausforderung stellte. Bis zu diesem Zeitpunkt wehte zwar auch schon ein steter Südostwind mit größeren Wellen inklusive, aber sobald wir die Brücke passieren wollten türmten sich diese noch weiter auf und aus gleichmäßig von vorne kommenden Wellenbergen wurden sehr große Kreuzwellen. Ehe wir uns versahen wurden wir aus unserer Komfortzone befördert und wir wollten schnellstmöglich an Land. Da die Gegebenheiten das aber nicht hergaben, mussten wir noch einige Kilometer bis zur nächsten Insel weiter paddeln. Hier verbrachten wir dann im Windschatten unsere Mittagspause, bevor es weiter auf die unruhige See ging.
				</p>
				<p>
					Bis zu diesem Zeitpunkt dachten wir noch, dass sobald wir das Delta des Flusses Tornelven, welchen wir bis zum Campingplatz aufwärts paddeln mussten, erreicht haben, der schwierigste Teil hinter uns liegt. Schnell mussten wir allerdings feststellen, dass hier die zweite Überraschung des Tages auf uns wartete. Die Strömungsgeschwindigkeit war so stark, dass ein Blick zum Ufer schnell deutlich machte, dass wir kaum vorwärts kamen. Wir kämpften uns also von Kehrwasser zu Kehrwasser und legten in die Paddelschläge all unsere Kraft. Wir schafften es schließlich, nachdem wir auch mal eine Stromschnelle herunter fahren durften und erreichten erschöpft den Campingplatz in Tornio und damit zum ersten Mal auf unserer Reise finnischen Boden. Finnland war erreicht und damit ein zweites Ereignis, was diesen Tag besonders machte.
				</p>
			</div>
			<div id="blog-38" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 38 - 16. Juli</h2>
				<p>
					Nachdem wir nun etwa die Hälfte unserer Gesamtstrecke zurückgelegt haben und unserer gestriger Tag doch anstrengender war als das, was wir von einem Ruhetag erwartet hatten, gönnten wir uns einen zweiten Ruhetag in Tornio. Einkaufen und die Stadt anschauen haben wir bereits gestern getan, weshalb wir heute den Großteil der Zeit auf dem Campingplatz blieben, Kaffee tranken, lasen und spielten. Und dazwischen war Zeit, dem Equipment nochmal etwas Pflege zukommen zu lassen und abends noch einmal die Sauna auszunutzen.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items38"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
				<p>
					Jetzt hoffen wir morgen auf einen herrlichen Paddeltag mit kleinen Wellen und leichtem Rückenwind. Mal sehen ob das klappt…
				</p>
			</div>
			<div  class="pt-11">
				<h2 class="font-weight-regular my-3">Luleå - Tornio</h2>
				<div class="my-7">
					<youtube fitParent video-id="OaNO2AXApgE" ref="youtube"></youtube>
				</div>
			</div>
			<div id="blog-41" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 41 - 19. Juli</h2>
				<p>
					Nachdem wir vor drei Tagen Tornio verlassen haben, hofften wir auf leichten Rückenwind und gutes Wetter. Zugegeben, eine Wunschvorstellung. So sollte es auch nicht werden: Vielmehr hatten wir Gegenwind und eine aufgewühlte See. Die letzten Tage kämpften wir uns mit unbeständigem Wetter und der dauerhaften Befürchtung, dass uns doch noch ein Regenguss ereilen wird, gen Süden. Schon seit Luleå mussten wir außerdem feststellen, dass die Wettervorhersage nur bedingt zu gebrauchen war, da es oft ganz anders kam als angekündigt. Das macht die ganzen Entscheidungen, die man täglich bezüglich Wind und Regen treffen muss, nicht gerade einfacher…
				</p>
				<p>
					Der heutige Morgen sah auch alles andere als vielversprechend aus. Als Tina am Morgen kurz das Zelt verließ, blickte sie in einen rund um dunklen Himmel. Uns war klar, dass wir erstmal abwarten müssen. Nur wenige Minuten später war es auch schon so weit und Regen und Hagel prasselten auf uns nieder. Das Wetter zwang uns also erstmal zur Gemütlichkeit. Wir tranken einen Kaffee, lasen, spielten ein Spiel und verbrachten so die ersten Stunden des Tages an Land. Nachdem der Regen überstanden war und es so aussah, dass ein größerer Schauer auch erstmal nicht kommt, packten wir die Sachen zusammen und starteten heute etwas verspätet mit dem Paddeln. Gegen Nachmittag hatten wir sogar das Glück, dass die Sonne sich zeigte und wir in der Mittagspause sogar unsere nassen Sachen trocknen konnten. Am Abend kamen wir auf einer kleinen Insel an und so endete ein etwas holprig begonnener Tag doch noch ganz schön und vor allem trocken.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items41"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div  class="pt-11">
				<h2 class="font-weight-regular my-3">Tornio - Raahe</h2>
				<div class="my-7">
					<youtube fitParent video-id="l4NB-WlRsz0" ref="youtube"></youtube>
				</div>
			</div>
			<div id="blog-46" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 46 - 24. Juli</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items46"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-48" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 48 - 26. Juli</h2>
				<p>
					Der gestrige Abend war wohl der schlimmste, den wir, seit Beginn unserer Reise vor mehr als 40 Tagen, hatten. Wir sind an diesem Tag bereits früh aufgebrochen, da die vorhergesagten Windgeschwindigkeiten gegen Mittag deutlich zunehmen sollten. Nach etwa 28 km wollten wir dann an einem Campingplatz nur schnell unsere Wasservorräte auffüllen und dann noch ein paar Kilometer raus fahren, um auf einer der vielen Inseln zu nächtigen.
				</p>
				<p>
					Die Inseln, die wir dann aber vorfanden, waren alles andere als gute Schlafplätze für die Nacht. Die Uferbereiche waren teils so steinig, dass ein Anlanden gar nicht möglich war und auf den Inseln selbst fanden wir keine ebene Fläche für das Zelt (zu viel Steine, zu viel Bäume). Mittlerweile setzte auch leichter, aber steter Regen ein und die Wolken am Himmel versprachen nichts Gutes. Schon jetzt war uns klar, dass der nächste Morgen mit nasser Kleidung und nassem Zelt beginnen wird.
				</p>
				<p>
					Notgedrungen bauten wir das Zelt dann auf einem großen Felsen auf, nachdem wir erst über eine Stunde damit beschäftigt waren, einen möglichst sicheren Platz für die Boote zu bauen: Wir schleppten Äste an, rollten Steine weg und wurden in dieser ganzen Zeit immer nasser und vor allem von einer Vielzahl von Mücken angegriffen. Irgendwann waren es so viele, dass wir resignierten und gar nicht mehr versuchten sie zu entfernen. Schließlich mussten die Boote schnellstmöglich „an Land“ gebracht und das Zelt aufgebaut werden. Während Tina in solchen Situationen emotional reagiert und dazu neigt, zu verzweifeln, ist Thorin ruhig und besonnen. Und diese Fähigkeit von ihm, in kniffligen Situationen den Mut nicht zu verlieren, ist auch genau das, was nötig ist, um diese zu meistern. Denn was sollte es auch bringen, wenn man sich ärgert, erstarrt oder verzweifelt? Davon liegen die Boote nachher nicht im Trockenen, noch baut sich das Zelt von selbst auf.
				</p>
				<p>
					Wir waren am Abend also doch noch im Schutz des Zeltes angekommen, aber ein weiterer Punkt der uns Sorgen machte, waren die Windgeschwindigkeiten, die für die kommenden Tage vorhergesagt waren (4-5 und Böen bis 7). Das Zelt war nicht so sicher aufgebaut, wie es bei solchen Bedingungen sein sollte (die Abspannleinen waren nur mit Steinen befestigt und nicht sicher mit Heringen im Boden verankert) und auch die Kajaks lagen mehr schlecht als recht.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items48_1"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
				<p>
					Trotz der hohen Windgeschwindigkeiten sahen wir uns heute morgen also gezwungen los zu fahren, um einen besseren Platz für uns und unsere Gefährten zu finden. Einen Platz, an dem wir zur Not auch einige Tage ausharren können. Zum Glück konnten wir die ersten 10 km im Schutz von Inseln fahren und mussten erst später in exponiertere Bereiche. Gegen Mittag erreichten wir dann glücklicherweise einen Sandstrand und konnten zwischen den Bäumen dahinter Zelt und Tarp aufstellen. Kurz darauf begannen auch einzelne Regenfronten über uns hinweg zu ziehen und wir waren froh, es rechtzeitig und sicher hierher geschafft zu haben. Ob wir morgen weiterfahren können wissen wir noch nicht, aber aktuell sieht es mehr nach einer Zwangspause aus.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items48_2"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-50" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 50 - 28. Juli</h2>
				<p>
					Nach der gestrigen Zwangspause wollten wir es heute nicht akzeptieren, erneut den Tag an Land verbringen zu müssen, zumal die Windvorhersage für die gesamte Woche mehr als schlecht aussah. Für heute war die Vorhersage für die Windgeschwindigkeit zumindest „nur“ bei 4 Bft und Böen bis 5 Bft und wenn wir es schaffen würden, heute bis zu den Kokkola vorgelagerten Inseln zu kommen, könnten wir morgen im Schutz dieser weiter fahren.
				</p>
				<p>
					Die erste Herausforderung aber war bereits heute Morgen - noch bevor wir überhaupt den ersten Kilometer fahren konnten - zu bewältigen. Unsere schöne Bucht, die uns gestern nämlich hervorragenden Schutz vor dem Wind aus Süd gab, erwies sich heute bei Nordwind als äußerst tückisch. Die Brandungswellen machten das Ablanden sehr schwer, da sie in schneller Abfolge immer wieder brachen und wir schauen mussten, nicht auf einen der vielen Felsen gedrückt zu werden. Eine Kenterung sollte hier nicht passieren. Wir begutachteten also erst mal in Ruhe die Bedingung und identifizierten eine möglichst steinfreie Schneise zum Rausfahren. Tina begann und nachdem sie es sicher aufs offene Wasser geschafft hatte, war Thorin an der Reihe. Wir waren beide sehr froh, dass wir diesen ersten herausfordernden Teil geschafft hatten und begaben uns nun in das aufgewühlte Meer.
				</p>
				<p>
					Leider ist es durch Bilder und Videos kaum möglich die Mächtigkeit der Wellen einzufangen, aber wenn man so in seinem Kajak sitzt und zur Seite schaut und die anrollenden Wassermassen sieht, bekommt man höchsten Respekt vor dieser Naturgewalt. Häufig kam es vor, dass wir uns gegenseitig nicht sehen konnten obwohl wir nur wenige Meter voneinander entfernt waren. Die Wellen waren so hoch, dass der andere einfach dahinter verschwand.
				</p>
				<p>
					Als wir heute Abend unser Zelt aufstellten waren wir sehr froh, dass dieser Tag so glücklich zu Ende gegangen war.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items50"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-53" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 53 - 31. Juli</h2>
				<p>
					Nach den letzten Tagen war das Paddeln heute die reine Freude. Als wir heute Morgen aus dem Zelt gekrochen kamen, schien bereits die Sonne und nur ein leichter Südwestwind wehte. Das Meer war vorwiegend ruhig. Unsere Mittagspause verbrachten wir an einem Strand mit vorgelagerten Felsen, was zum Baden einlud. Es war so schön hier, dass wir heute auch länger bei unserer Mittagspause verweilten als üblich. Aber das Wetter sah für heute stabil aus, weshalb sollten wir uns also beeilen?
				</p>
				<p>
					Als wir heute nach etwa 25 km eine Insel erreichten, entschieden wir uns auch dazu, es für heute sein zu lassen. Tinas Handgelenk macht seit heute Morgen Problene und ein paar Kilometer weniger paddeln sind da bestimmt auch nicht verkehrt.
				</p>
				<p>
					Da das Anlanden wieder nicht so einfach war, beschäftigten wir uns eine Zeit lang mit dem Bauen eines kleinen Hafens für unsere Boote. Wir bewegten also einzelne Steine von A nach B und versuchten so eine Mole zu errichten. Im Vergleich zum letzten Mal, als wir improvisieren mussten, hat das heute wirklich Spaß gemacht: Die Sonne schien, es war warm und wir hatten keinen Zeitdruck.
				</p>
				<p>
					Die Insel beschenkte uns außerdem noch mit Birken- und Sandpilzen für das Abendessen und jede Menge Heidelbeeren.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items53"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-56" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 56 - 03. August</h2>
				<p>
					Da der Wind gestern Mittag stark zunahm, strandeten wir auf einer Insel vor Vaasa. Die Windvorhersage für die nächsten drei Tage sah höchst bedenklich aus (4-5 Bft und Böen bis 7 Bft), wir hatten bis auf etwa 5 l kein Wasser mehr und auch das Essen würde uns bei einer so langen Zwangspause rasch ausgehen, zumal unsere nächste geplante Einkaufsmöglichkeit noch etwa drei Fahrtage entfernt war (Vaasa war nämlich eigentlich nicht vorgesehen). Wir standen also vor einem Problem und das Finden einer guten Lösung erwies sich als äußerst schwierig und dauerte noch drei Kaffee und 1-2 h lang.
				</p>
				<p>
					Unser Plan sah dann so aus, dass wir den Wecker für den morgigen Tag, also heute, auf 4 Uhr stellten und die ruhigere Phase bis 9 Uhr (4 Bft und Böen von 5 Bft) nutzen, um bis zu einem Campingplatz nach Vaasa zu kommen. Von hier könnten wir dann einkaufen und entspannt die windigen Tage abwarten, ohne dass uns Essen und, noch wichtiger, das Wasser ausgehen würde.
				</p>
				<p>
					Tina machte in der ganzen Nacht kaum ein Auge zu. Der Wind schwächte über die Nacht kaum ab und man hörte ständig das Rauschen des Windes in den Bäumen und wurde daran erinnert, was in den frühen Morgenstunden auf uns warten würde.
				</p>
				<p>
					Als der Wecker klingelte war es fast eine Erleichterung. In Rekordzeit war das Zelt abgebaut und die gesamte Trockenbekleidung angezogen, da laut Vorhersage uns auch in naher Zeit eine Regenfront ereilen sollte. Als wir auf dem Wasser waren, war es trotz der Umstände ein sehr magischer Moment, da die Sonne gerade am Aufgehen war und der Himmel rot glühte.
				</p>
				<p>
					Thorin, der überhaupt kein Morgenmensch ist, paddelte schweigsam und man sah ihm an, dass jeder Meter ihn Anstrengung kostete. Die Schmerzen in der Schulter, die ihn seit einigen Tagen plagen, erschwerten das Ganze außerdem zunehmend.
				</p>
				<p>
					Um 8:30 Uhr hatten wir es dann endlich zum Campingplatz geschafft, und kamen außerdem noch gerade pünktlich zur Morgensauna.
				</p>
				<p>
					Am Mittag machten wir uns dann noch auf den Weg in die Stadt und die Gegend anzuschauen. Alles in allem war der restliche Tag aber eher ruhig. Am Ende können wir sagen, dass es vermutlich die richtige Entscheidung war, in Vaasa einzukehren: Der Wind war schon heftig!
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items56"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-59" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 59 - 06. August</h2>
				<p>
					Bereits gestern Abend begann ein steter Dauerregen, der einen in wenigen Minuten gut durchnässt hatte. Dieser Regen hörte auch die ganze Nacht über nicht auf und heute Morgen wurden wir von dem Prasseln des Regens auf unserm Zelt geweckt. Anders als die Vorhersage, ist der Regen nicht weniger geworden und dauerte auch noch bis mittags an. Einziger Vorteil dieser Regenphase waren die deutlich ruhigeren Windbedingungen.
				</p>
				<p>
					Wir brachen aber dennoch nicht auf, da sonst neben Zelt noch viele andere Sachen schnell nass gewesen wären. Wir harrten also erneut aus und das ist gar nicht so leicht zu ertragen wie es vielleicht scheinen mag.
				</p>
				<p>
					Sobald der Regen aufhörte, packten wir zusammen und paddelten gegen 14 Uhr los. Viel erwarteten wir heute nicht mehr, aber wir wollten einfach weiterkommen. Die Windgeschwindigkeit hatte mittlerweile deutlich zugenommen (4 Bft und Böen von 6 Bft) und wir bewegten uns bei diesem Gegenwind nur langsam vorwärts.
				</p>
				<p>
					Später setze noch mal leichter Regen ein und das Finden eines Lagerplatzes war heute auch äußerst schwierig. Schon vor einigen Wochen mussten wir feststellen, dass hier in Finnland fast auf jeder noch so kleinen Insel ein Haus steht und Streusiedlungen sehr beliebt sind. Notgedrungen landeten wir an einem öffentlichen Badestrand neben einem Restaurant, in dem wohl gerade eine Bandprobe stattfand, an. Normalerweise ziehen wir die Abgeschiedenheit der Wildnis vor, welche in Finnland jedoch schwer zu finden ist, so dass wir uns heute mit der Nähe zur Zivilisation begnügen mussten und darauf hoffen, dass sich niemand daran stören wird.
				</p>
				<p>
					Ob wir morgen weiterkommen ist erneut offen, da die Vorhersage sehr hohe Windgeschwindigkeiten angekündigt hat. Es sind nicht gerade unsere glücklichsten Tage auf der Reise…
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items59"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-61" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 61 - 08. August</h2>
				<p>
					Obwohl der gestrige Tag wegen des Windes sehr anstrengend war, sind wir trotzdem recht weit gekommen. Allerdings ist etwas passiert, was leicht Das Ende unserer Fahrt hätte bedeuten können: bei Thorins Boot ist plötzlich das Seil der Steueranlage gerissen, so dass sich nur noch das rechte Pedal bedienen lässt. An sich lässt sich auch ohne Steuer fahren, jedoch ist man dann viel mehr ein Spielball von Wind und Wellen. Zum Glück funktioniert das Skeg noch, womit der Geradeauslauf erheblich erleichtert wird.
				</p>
				<p>
					Recht schnell war Thorin klar, was kaputt gegangen war und ihm war auch klar, dass das nicht so einfach repariert werden konnte: das gerissene Seil musste erneut am Steuerkreuz befestigt werden, welches sich jedoch unter einer verschraubten Abdeckung befindet, zu welcher wir natürlich nicht den passenden Schraubenzieher dabei haben.
				</p>
				<p>
					Erstmal ging es also nur mit Skeg weiter. Am nächsten Tag konnte er endlich mit Lettmann telefonieren und erfuhr, dass die Schrauben nur leicht angezogen sind. Noch einigem Probieren ließen sich diese mit der Säge vom Schweizer Messer herausdrehen und die Reparatur konnte nun erfolgreich durchgeführt werden.
				</p>
				<p>
					Mit dem frisch repariertem Steuer brachten wir die letzten Kilometer des Tages voller Elan hinter uns.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items61"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-63" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 63 - 10. August</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items63"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-68" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 68 - 15. August</h2>
				<p>Heute, nach nur 5 km Fahrt, erreichten wir Rauma und damit auch die letzte gute Möglichkeit, unsere Essensvorräte noch einmal aufzufüllen, bevor die Querung bei den Åland Inseln beginnt. Anders als üblich mussten wir heute für 14 Tage einkaufen. Folglich war unser Einkauf recht groß und auch das Beladen der Boote nahm einige Zeit in Anspruch.
				</p>
				<p>
					Als wir los kamen war es bereits Mittag, aber das Wetter war gut und es wehte nur ein leichter Südwestwind. Leider war abends die Lagerplatzsuche gar nicht so einfach, so dass wir erst recht spät unser Zelt aufstellen konnten. Heute war mal wieder ein langer Tag, aber dafür sollten wir für die nächsten Tage Ruhe vom Einkaufen haben.
				</p>
			</div>
			<div id="blog-71" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 71 - 18. August</h2>
				<p>
					Als wir heute Morgen aus dem Zelt gekrochen kamen, sahen wir erstmal einen Nebelschleier über dem Wasser. Die 8 km entfernt liegende Insel, die wir heute ansteuern wollten, war gänzlich im Nebel verschwunden. Die Stimmung auf dem Wasser hatte schon etwas mystisches, da recht schnell um uns alles Land verschwunden war und wir nur noch in ein Weiß fuhren. Die Kompasspeilung behielt aber Recht und so erblickten wir nach über einer Stunde endlich wieder Land. So ging es den ganzen restlichen Tag weiter, wobei gegen Mittag der Nebel noch dichter wurde und man nur noch etwa 50 m weit sehen konnte. Wir hangelten uns also von Insel zu Insel und manchmal hörte man das Nebelhorn irgendeines Schiffes da draußen.
				</p>
				<p>
					Heute war es außerdem soweit, dass wir an Uusikaupunki vorbeigefahren sind und damit die letzte große Kursänderung auf unserer Reise stattgefunden hat. Während wir die letzten Wochen hauptsächlich Richtung Süden gefahren sind, werden wir nun die letzten etwa 200 km weiter Richtung Westen fahren. Vor uns liegen tausende von kleinen Inseln, die wie Trittsteine einen Weg über die Ostsee bilden und damit den Übergang zwischen Schweden und Finnland ermöglichen.
				</p>
				<p>
					Auf diesen letzten Teil unserer Reise freuen wir uns schon eine ganze Weile. Die Åland Inseln sollen, was man so hört, wunderschön und eine tolle Gegend zum Paddeln sein.
				</p>
				<p>
					Um die Essensversorgung haben wir uns bereits vor drei Tagen gekümmert und hier hoffen wir, dass unsere Kalkulation stimmt und die Natur uns vielleicht auch noch mit Pilzen und Beeren beschenken wird. Das Trinkwasser wird auf dieser Strecke eher herausfordernd sein: Campingplätze oder Häfen gibt es auf unserer Route nicht, sodass wir auf kleine vom Regenwasser gespeiste Wasserbecken auf den Inseln hoffen.
				</p>
				<p>
					Der Endspurt unserer Reise hat begonnen und wir sind gespannt, was in den letzten, noch vor uns liegenden Tagen auf uns warten wird.
				</p>
			</div>
			<div id="blog-72" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 72 - 19. August</h2>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items72"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-73" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 73 - 20. August</h2>
				<p>
					Heute war wohl einer der windigsten Tage der letzten Wochen und so war uns schnell klar, dass wir heute keinen Kilometer fahren, sondern brav an Land bleiben werden. Das war, wenn man rückblickend auf die vergangenen Stunden blickt, vermutlich auch ganz gut, denn wir mussten regelmäßig die Befestigungen des Zeltes mit immer noch größeren Steinen verbessern, da der Wind auch diese noch in Bewegung setzen konnte.
				</p>
				<p>
					Wir erkundeten also die zum Großteil aus Fels bestehende Insel und Thorin konnte unsere Vogelarten-Liste um fünf neue Arten ergänzen, sodass wir nun auf dieser Reise bereits auf 112 verschiedene Arten kommen.
				</p>
				<p>
					Gegen Abend kam zu dem Wind noch starker Regen dazu, was in der Kombination eher suboptimal ist, da der Wind das Außenzelt an das Innenzelt gedrückt hat und so doch ein wenig Nässe in unsere sonst so gute Festung dringen konnte.
				</p>
				<p>
					Der morgige Tag soll leider nur zum Teil besser werden, da wir uns für starken Wind am Vormittag und Trockenheit oder starken Regen und wenig Wind am Mittag entscheiden können. Vielleicht wird es ein Mittelweg werden oder vielleicht doch nochmal ein Landgang? Wir werden sehen… Zumindest soll es übermorgen besser werden.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items73"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-74" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 74 - 21. August</h2>
				<p>
					Um an die Frage des Vortages anzuschließen, wie wir den heutigen Tag gestalten werden: Ein Mittelweg zwischen Wind und Regen gab es nicht. Der ganze Wind des Vortages war heute Morgen weg und zurück blieb nur der Regen. Also packten wir das nasse Zelt ein, zogen die Paddeljacken an und bereiteten uns mental auf einen Regentag vor. Es dauerte keine halbe Stunde und die ersten Tropfen kamen vom Himmel und damit begann ein etwa 5 Stunden anhaltender Dauerregen. Natürlich waren wir trotz der Jacken schnell bis auf die Haut durchnässt und an eine Pause war nicht zu denken. Wir wären nur noch nasser geworden und außerdem wurde es auch schnell kalt sobald man aus der Bewegung kam. Die nun folgenden etwa 34 km paddelten wir also nahezu pausenlos durch und stellten dann gegen Nachmittag, als der Regen aufhörte, das Zelt auf und machten Feierabend für heute.
				</p>
				<p>
					Das Ende unserer Reise rückt näher und so buchten wir heute auch die Fähre zurück nach Deutschland. Am 27. August werden wir von Malmö zurück nach Travemünde fahren, also ab jetzt nicht einmal mehr eine Woche.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items74"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-76" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 76 - 23. August</h2>
				<p>
					Finale! Heute war ein besonderer Tag, nicht nur weil es unser vorletzter Tag auf dem Wasser sein würde, sondern auch weil heute diese eine letzte Querung zurück nach Schweden bevorstand.
				</p>
				<p>
					Da wir beide etwas angespannt waren, wachten wir entsprechend früh auf. Doch die Wettervorhersage hatte sich zum Vorabend kaum geändert und das Meer war vergleichsweise ruhig. Wir hatten eine Windstärke von 3 Bft mit dem Ausblick, gegen Mittag noch weiter abzunehmen. Es konnte also los gehen.
				</p>
				<p>
					Gestern Abend sind wir auf Skaven, einer kleinen, sehr kargen, aber dennoch interessanten Insel angekommen und von hier trennten uns etwa 15 km offenes Meer von Märket. Märket, eine noch kleinere Insel mit Leuchturm auf der die Grenze zwischen Schweden und Finnland verläuft.
				</p>
				<p>
					Da wir nicht genau wussten wie Märket aus der Ferne aussieht und sich weit hinten am Horizont mehrere winzige Spitzen abzeichneten, paddelten wir erstmal auf dem zuvor bestimmten Kurs von 256 Grad los. 10 km später und ein Blick auf das GPS Gerät machten aber deutlich, dass wir zu weit nach Norden abgedriftet waren, also nochmal Kurs korrigieren und dann wurde uns auch klar was Märket ist.
				</p>
				<p>
					Dort angekommen wurden wir sehr herzlich von zwei Frauen begrüßt, die hier eine Woche als Freiwillige den Leuchturm bewohnen und Besucher Willkommen heißen. Wir bekamen eine kleine Führung durch die Gebäude und die Geschichte der Insel wurde uns erzählt. Zum Abschluss bekamen wir außerdem noch einen Kaffee, wohlgemerkt der erste richtige seit Luleå, und frisch gebackene Zimtschnecken.
				</p>
				<p>
					Hier auf Märket wird auch die finnische Saunakultur so richtig deutlich, denn obwohl die Insel so winzig ist, gibt es hier für die Freiwilligen eine, wie hätte es anders sein können, Sauna. Stolz wurden uns erzählt, dass es außerdem die westlichste Sauna von ganz Finnland ist.
				</p>
				<p>
					Obwohl wir uns hier sehr wohl gefühlt haben, mussten wir am Ende doch noch weiter, da eine zweite größere Querung von etwa 10 km nach Understen noch bevorstand. Auch diese war nach etwa 1,5 h gemeistert doch ein Anlanden war nicht möglich, da die Insel, so wie es aussah, militärisches Sperrgebiet war. Gelbe Warnschilder, die in wenigen Metern Abstand voneinander um die ganze Insel aufgestellt waren, schrieen einem förmlich an „Mach dich hier weg!“. Bei den nun folgenden Inseln war es immer das gleiche und so kamen wir erneut zu keiner Mittagspause. Erst etwa 22 km nach Märket fanden wir einen Platz und schlugen dann auch hier unser Lager auf.
				</p>
				<p>
					Diese letzten zwei Tage waren doch etwas emotional, da mit dem Zurücklassen von Åland uns mehr als zuvor klar wurde, dass das Ende sehr nahe ist. Jetzt sind es noch etwa 15-20 km bis nach Hargshamn, die wir morgen in wenigen Stunden zurückgelegt haben werden. Und dann? Dann geht diese Reise von uns zu Ende, doch wir haben die letzten Hunderte von Kilometern auch schon genutzt, neue Abenteuer zu erträumen.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items76"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
			<div id="blog-77" class="pt-11">
				<h2 class="font-weight-regular my-3">Tag 77 - 24. August</h2>
				<p>
					Nach 77 Tagen, 2008 km und unzählbar vielen Paddelschlägen sind wir heute am frühen Nachmittag wieder an unserem Startpunkt angekommen. Je näher wir unserem Ziel kamen, desto wehmütiger und aufgeregter wurden wir zugleich.
				</p>
				<p>
					Wir erinnern uns noch gut an den Tag vor drei Monaten, als wir Vingilot und Cúthalion beladen hatten, zu Wasser getragen und unsere ersten Paddelschläge auf der Ostsee gemacht hatten. Unser Projekt schien zu diesem Zeitpunkt noch so groß und etliche Kilometer lagen noch vor uns. Damals konnten wir uns nicht vorstellen, wie es wohl sein wird, wenn wir hier wieder anlanden werden. Heute war es dann doch soweit.
				</p>
				<p>
					Diese Reise hat uns doch das ein oder andere Mal aus unserer Komfortzone gebracht, wir haben ein paar Mal falsche Entscheidungen getroffen. Doch wir dürfen auch auf wundervolle und einzigartige Augenblicke zurückblicken und sind froh, dass wir vor über einem Jahr angefangen haben, dieses Projekt Schritt für Schritt zu realisieren.
				</p>
				<p>
					Nun am Ende wollen wir aber außerdem noch die Gelegenheit nutzen, uns zu bedanken. Danke an unseren Freund Alexander Zeller, der in vielen Stunden Arbeit den Quellcode für diese Webseite geschrieben und uns zur Verfügung gestellt hat. Danke auch an Tinas Papa, der während der letzten Wochen unsere Basisstation in Deutschland war und mit viel Hingabe zuverlässig jeden Abend GPS-Tracks, Bilder und Logbuch-Einträge auf die Webseite gestellt hat. Wenn es hier mal zu Verzögerungen kam, lag es mehr an unserer Internetverbindung als an seiner Arbeit. Und nicht zuletzt Danke an euch alle, die ihr unsere Reise verfolgt habt. Wir haben uns immer sehr über eure Nachrichten gefreut, die uns auf verschiedenen Wegen erreicht haben.
				</p>
				<p>
					In den nächsten Wochen wird es zwar keine weiteren Tracks mehr geben, aber wir wollen dennoch weitere Videos schneiden. Dort sind wir schließlich erst in Tornio angekommen.
				</p>
				<p>
					Und zuletzt noch ein Nachtrag zu gestern: Wir haben es mit unserem Besuch auf Märket in das <a href="https://www.majakkaseura.fi/talkooviikko-34-maisu-taina-anni-ja-markku/" target="_new">Märket-Tagebuch</a>  geschafft.
				</p>
				<v-card class="mx-auto my-7" outlined>
					<v-carousel hide-delimiter-background hide-delimiters>
					<v-carousel-item
						v-for="(item, i) in items77"
						:key="i"
						:src="item.src"
						reverse-transition="slide-transition"
						transition="fade-transition"
						transition-duration="5s"
					></v-carousel-item>
					</v-carousel>
				</v-card>
			</div>
		</v-col>
		<v-col cols="0" md="1" lg="2"></v-col>
	</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
	return {
		items77: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0824%2F22-08-24%2005-16-53%201288.jpg?alt=media&token=832acb01-60fa-49eb-819a-bd565767b804",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0824%2F22-08-24%2009-11-25%201286.jpg?alt=media&token=870e2329-7d3e-4d66-b167-0e665fb64f07",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0824%2F22-08-24%2010-42-11%201287.jpg?alt=media&token=1200a969-9830-4cca-9a79-8f0b81988a8e",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0824%2F22-08-24%2012-17-51%201285.jpg?alt=media&token=c43c1e9f-619e-4046-901b-bd173d58df51",
		},
		],
		items76: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-22%2013-47-28%201275.jpg?alt=media&token=9d974d20-86bf-4804-be2f-4bf0fd0b19e4",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-22%2013-51-47%201274.jpg?alt=media&token=9211092b-d30d-4a82-89db-d3b3f8788794",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-22%2014-22-32%201278.jpg?alt=media&token=e3f5edb9-bc4b-4177-b14f-40183241f8a4",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-23%2002-27-08%201277.jpg?alt=media&token=da872128-cb92-46b7-bdfa-97bfaa649d64",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-23%2003-11-49%201279.jpg?alt=media&token=33d4ded0-e2ed-4039-8d3e-0bb4355b63cd",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-23%2003-15-47%201273.jpg?alt=media&token=390ea121-502b-424e-9e93-ef6e2cd619fb",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-23%2003-30-36%201276.jpg?alt=media&token=65df77cc-fbb8-48ba-82c3-ac1a0265965f",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0823%2F22-08-23%2004-12-50%201280.jpg?alt=media&token=b72e75f7-faea-4ff3-9670-cba790c1880a",
		},
		],
		items74: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0821%2F22-08-21%2006-20-41%201252.jpg?alt=media&token=8595b9a2-e22a-4bcd-a681-14b991224bd3",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0821%2F22-08-21%2011-00-09%201253.jpg?alt=media&token=2fad9ad9-74a3-470d-a3ae-0e1ba3bbbe01",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0821%2F22-08-21%2011-01-50%201257.jpg?alt=media&token=e6c5fdd5-23c1-434a-bbe9-f080b627cab0",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0821%2F22-08-21%2011-12-50%201254.jpg?alt=media&token=67e17b3e-c215-4b64-b670-1ce25daa42c1",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0821%2F22-08-21%2011-13-35%201255.jpg?alt=media&token=27552369-b512-46b4-ae45-08852fe74a82",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0821%2F22-08-21%2011-19-31%201256.jpg?alt=media&token=9adb269b-b12f-4dfc-85a6-d4a3715f6fd2",
		},
		],
		items73: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0820%2F22-08-20%2006-58-58%201249.jpg?alt=media&token=abec8b2c-289c-4992-8cb5-90b4f6d95667",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0820%2F22-08-20%2007-16-05%201250.jpg?alt=media&token=63c48bcc-0f9d-4039-a875-ffdefa5ae0a9",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0820%2F22-08-20%2008-26-05%201251.jpg?alt=media&token=97fb11e3-042a-41bb-88bb-e6fb16043f5a",
		},
		],
		items72: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0819%2F22-08-18%2001-03-41%201228.jpg?alt=media&token=0ce61c8c-e59d-4417-93a6-50270e72a0c3",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0819%2F22-08-18%2003-26-37%201227.jpg?alt=media&token=83989749-6764-4b97-b2f1-f001274e4d03",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0819%2F22-08-18%2003-26-41%201226.jpg?alt=media&token=468ae11f-68d9-43f9-ac09-00f8d48aec11",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0819%2F22-08-19%2001-27-06%201229.jpg?alt=media&token=75aaae50-8337-4b96-8a8d-ccfb9c120c5c",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0819%2F22-08-19%2005-43-46%201225.jpg?alt=media&token=89a16d52-12ae-4d10-b02c-6b2e39d77b12",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0819%2F22-08-19%2011-56-14%201233.jpg?alt=media&token=a1bcf158-7bda-47a6-8fd3-18bd94717f3d",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0819%2F22-08-19%2011-57-14%201232.jpg?alt=media&token=3556a5fc-b940-41c7-9dba-37b3bd72416a",
		},
		],
		items63: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2010-48-17%201189.jpg?alt=media&token=33b0992e-6717-4533-ad0e-17ef89d12111",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2012-39-46%201190.jpg?alt=media&token=2e950b0c-5348-4fe7-89a6-8d07181c50b3",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2013-58-08%201191.jpg?alt=media&token=6253d889-6e4e-4996-92cf-713759e58b6b",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2014-20-48%201193.jpg?alt=media&token=a37497a7-e5cd-493e-aa2a-c921edf39aa1",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2014-26-05%201194.jpg?alt=media&token=96516f03-80c9-4f27-8b22-63b88ee66484",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2014-28-12%201195.jpg?alt=media&token=a589f688-fa12-4d6b-8081-645983776586",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2014-50-42%201197.jpg?alt=media&token=b9c8608c-6627-4944-b0e0-af09b0de50e4",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0810%2F22-08-10%2014-51-17%201196.jpg?alt=media&token=e571ba93-12ae-4cf0-9f12-eb5dd679b8fd",
		},
		],
		items61: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-08%2006-13-14%201175.jpg?alt=media&token=12c5bb59-c8cf-4563-b92b-e4601030a6bb",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-08%2006-21-15%201173.jpg?alt=media&token=d89405cc-6581-4a52-bdcc-64eb02fc7cd5",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-08%2011-01-05%201177.jpg?alt=media&token=eb234372-ccf0-401b-82c8-831bd39e1960",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-08%2011-01-41%201172.jpg?alt=media&token=ef4d50e4-fd4a-4205-bd1c-99c94e480baa",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-08%2011-58-24%201171.jpg?alt=media&token=68008cbf-02fe-4c1f-bc97-d9d81ba31920",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-08%2014-40-07%201174.jpg?alt=media&token=d577fe11-9973-409d-8bd3-74cd24734259",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-08%2014-45-09%201176.jpg?alt=media&token=b4512b44-5ace-492f-b783-242301c6d510",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-09%2021-39-03%201178.jpg?alt=media&token=cb4656cb-2a13-4f75-9c95-329937cfc439",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-09%2021-41-16%201179.jpg?alt=media&token=e18d46f2-6cba-41d6-aa82-f647bf720b02",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0808%2F22-08-09%2021-41-40%201180.jpg?alt=media&token=010fa63c-4ab1-4aa8-8722-64419c11b452",
		},
		],
		items59: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0806%2F22-08-06%2005-38-02%201127.jpg?alt=media&token=4f0ddc6d-c573-4eab-aca0-603fe2fc3e65",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0806%2F22-08-06%2010-35-29%201130.jpg?alt=media&token=85d1e57e-5ca8-450c-ad18-ef41dd82c924",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0806%2F22-08-06%2011-49-28%201132.jpg?alt=media&token=cc42ab67-02ea-4d2a-b81c-b8909a356299",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0806%2F22-08-06%2013-18-18%201131.jpg?alt=media&token=c4810a7f-a32d-4918-ad64-b84609136e9c",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0806%2F22-08-06%2013-19-40%201129.jpg?alt=media&token=d3461715-a90f-4722-b9e5-eb5eac8cc579",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0806%2F22-08-06%2013-22-41%201128.jpg?alt=media&token=b1e354df-a163-4048-9837-fea722991bd8",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0806%2F22-08-06%2020-31-12%201133.png?alt=media&token=2abfe71f-76c0-4a28-bad1-25cc75d8f870",
		},
		],
		items56: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0803%2F22-08-02%2009-43-48%201113.jpg?alt=media&token=cb0fcdc3-c229-4841-ad7f-486ac60c5b03",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0803%2F22-08-02%2013-27-32%201116.jpg?alt=media&token=ef729884-ab33-4c6d-8c42-d93b62ed4bf0",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0803%2F22-08-02%2013-28-31%201117.jpg?alt=media&token=67dca77a-714d-4288-8b5f-3cb62d9b642f",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0803%2F22-08-02%2023-52-11%201112.jpg?alt=media&token=2671bc40-9a2e-432f-a2da-3aadd4226399",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0803%2F22-08-03%2008-16-31%201118.jpg?alt=media&token=15c06d29-6a29-48fd-b5e0-04f935a79526",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0803%2F22-08-03%2008-27-30%201115.jpg?alt=media&token=97b6bcd9-1bc3-4b7c-b85a-a870a2322382",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0803%2F22-08-03%2008-41-48%201114.jpg?alt=media&token=cc7e72e8-79fa-4720-9f7d-28da7df9eb4c",
		},
		],
		items53: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0731%2F22-07-31%2003-09-06%201094.jpg?alt=media&token=e4b00541-111e-4a20-a844-2942aafe66d4",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0731%2F22-07-31%2005-14-32%201096.jpg?alt=media&token=18547543-78d4-4d08-9aa5-2b323507858b",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0731%2F22-07-31%2011-56-50%201095.jpg?alt=media&token=ddbf8586-b1c1-499e-b42e-712d1fd4de0a",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0731%2F22-07-31%2011-58-33%201097.jpg?alt=media&token=a41a46dd-60cf-47a0-998b-e7066cce70bd",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0731%2F22-07-31%2021-31-07%201092.jpg?alt=media&token=711337e4-b1ed-41b9-acab-7b2260964686",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0731%2F22-07-31%2021-31-53%201093.jpg?alt=media&token=bbea1bba-3424-4d85-9ae7-42c575f5c312",
		},
		],
		items50: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0728%2F22-07-28%2002-13-00%201044.jpg?alt=media&token=2f9c4fc0-afd3-4616-bdc4-007529c6d32d",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0728%2F22-07-28%2006-44-36%201045.jpg?alt=media&token=7bc0730f-499f-4a7c-93b5-a256febd2118",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0728%2F22-07-28%2008-29-00%201046.jpg?alt=media&token=5cf1979d-7e2d-4cc2-ba78-4b7bcd25aab7",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0728%2F22-07-28%2010-23-52%201047.jpg?alt=media&token=e47e823d-a244-498c-976a-3a7a3fe914e0",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0728%2F22-07-28%2012-00-56%201048.jpg?alt=media&token=df82e095-1e49-4f74-8edc-f2e7f13c5671",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0728%2F22-07-28%2012-01-07%201049.jpg?alt=media&token=0dd312f7-2a82-4d59-8d51-d3080b764534",
		},
		],
		items48_2: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0726%2F22-07-26%2011-12-30%201036.jpg?alt=media&token=d91a3171-9678-472d-98e3-05339121e694",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0726%2F22-07-26%2011-13-06%201035.jpg?alt=media&token=60d134b4-dd8a-4957-acdd-8be6813d0087",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0726%2F22-07-26%2011-13-30%201033.jpg?alt=media&token=ce5c3da8-e5c2-47f0-ae55-d4cacb28c6c7",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0726%2F22-07-26%2011-14-54%201034.jpg?alt=media&token=ba8887f7-a17e-49ca-b588-6c5c8f428b45",
		},
		],
		items48_1: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0726%2F22-07-26%2000-43-23%201027.jpg?alt=media&token=f315efb5-d204-441a-99c0-959685223a90",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0726%2F22-07-26%2000-43-32%201028.jpg?alt=media&token=1166c20c-c4d1-4326-841b-6805e42f9211",
		},
		],
		items46: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-22%2009-14-42%201024.jpg?alt=media&token=09b492d9-bc83-48ac-9420-b686c3b4b70a",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-22%2014-54-48%201023.jpg?alt=media&token=9bef6834-df23-4e8a-9c90-bc35ec678cb9",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-23%2008-04-02%201022.jpg?alt=media&token=c1036b00-680b-4a8b-826d-46eee23d7728",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-23%2008-16-56%201021.jpg?alt=media&token=78a1cf53-3bde-4beb-80a9-64f7e9aa1c9d",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-23%2011-25-47%201020.jpg?alt=media&token=2d8f5960-e78d-4ce4-ab6d-76cea12e7801",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-23%2011-25-48%201019.jpg?alt=media&token=a6ca73ba-b075-4a72-8a59-be4cf9e88b1d",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-24%2005-30-17%201017.jpg?alt=media&token=5292a65d-eed0-48b9-a076-9c7da4d62777",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0722-24%2F22-07-24%2010-11-45%201018.jpg?alt=media&token=a2a6739e-4bc7-48d2-bbea-26627c586504",
		},
		],
		items41: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0719%2F22-07-19%2002-38-07%200997.jpg?alt=media&token=e015bc38-d969-419f-b653-4fe6e1539b43",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0719%2F22-07-19%2006-43-49%200996.jpg?alt=media&token=be774798-c05b-46e3-96bb-49d2f426aae3",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0719%2F22-07-19%2008-00-44%201001.jpg?alt=media&token=8d59bd46-46ea-4783-8401-68be9f15343f",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0719%2F22-07-19%2008-00-50%201002.jpg?alt=media&token=76249c6d-3f18-43d2-ab88-c2f317812542",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0719%2F22-07-19%2008-00-54%201003.jpg?alt=media&token=26a8bd67-f8c7-4bdd-b8b0-3dfd96289e16",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0719%2F22-07-19%2009-55-42%200998.jpg?alt=media&token=33035d9a-af35-44d0-81be-6077d33293d3",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0719%2F22-07-19%2013-12-44%200999.jpg?alt=media&token=74eede53-72ac-45df-b8de-797aaf6dbe51",
		},
		],
		items38: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0716%2F22-07-14%2009-21-53%200976.jpg?alt=media&token=f448c14e-bade-4f7c-97f3-e4628a379e27",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0716%2F22-07-14%2010-26-35%200982.jpg?alt=media&token=c324bf16-a125-4de4-a990-bd184b82a30a",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0716%2F22-07-15%2004-16-54%200978.jpg?alt=media&token=6cd5619a-4864-4523-8f59-d6201197a0cf",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0716%2F22-07-15%2005-20-30%200977.jpg?alt=media&token=0c1917b9-54ca-4d2d-b2ee-6d84d87b13c8",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0716%2F22-07-15%2005-20-56%200981.jpg?alt=media&token=b8319f38-99f2-4c69-85e7-7b9ea9438d86",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0716%2F22-07-15%2006-33-48%200980.jpg?alt=media&token=94fd7521-e1e7-48d1-82bc-06ae2150649b",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0716%2F22-07-15%2006-34-35%200979.jpg?alt=media&token=97c80c2f-dd0a-4dcd-9083-a639d7ed4eb6",
		},
		],
		items35: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0713%2F22-07-13%2007-43-48%200939.jpg?alt=media&token=ba04a592-7631-4355-971d-fb62c0664e53",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0713%2F22-07-13%2008-06-58%200942.jpg?alt=media&token=74cdb04d-c666-4d9e-a20e-34e234467e14",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0713%2F22-07-13%2008-07-02%200943.jpg?alt=media&token=5e2c225a-096e-4391-9c99-2ac467e16b83",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0713%2F22-07-13%2008-10-22%200945.jpg?alt=media&token=23a84a3b-190f-40fb-876a-fea1c9b9087e",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0713%2F22-07-13%2008-10-34%200940.jpg?alt=media&token=ce79079a-fb70-4ecc-a279-0447f73fece1",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0713%2F22-07-13%2012-43-49%200941.jpg?alt=media&token=fe8efbd2-a67d-41f4-b3e8-9601762688eb",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0713%2F22-07-13%2012-54-55%200944.jpg?alt=media&token=1972fb36-298c-4076-8d1c-c381620a064f",
		},
		],
		items34: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0712%2F22-07-12%2007-12-52%200927.jpg?alt=media&token=197f5ce7-bf63-4426-9aaf-c38f66d68784",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0712%2F22-07-12%2007-15-43%200928.jpg?alt=media&token=e84400fa-43fc-45d4-84dd-537b9bfcbeb8",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0712%2F22-07-12%2011-02-39%200929.jpg?alt=media&token=40e26c64-38c3-4487-8585-43426f2b41e7",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0712%2F22-07-12%2011-02-48%200931.jpg?alt=media&token=79b65eb7-3cb1-4306-8110-61ba157d7dfe",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0712%2F22-07-12%2012-18-57%200930.jpg?alt=media&token=87480f59-f578-4997-bcf8-2873dc20a2ee",
		},
		],
		items29: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-07%2010-31-36%200899.jpg?alt=media&token=d089e831-af38-410a-8998-1c46f844bed0",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-07%2012-09-21%200898.jpg?alt=media&token=7374e1e8-e305-4b08-a538-f09e5cbd1148",
		},
		],
		items28: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-06%2003-22-56%200897.jpg?alt=media&token=f41a964d-f8b2-44a0-8e91-2c4dca6e16aa",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-06%2005-32-59%200892.jpg?alt=media&token=0a57029c-058f-4520-a4a2-815c31abf1c6",
		},
		],
		items27: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-04%2013-40-44%200894.jpg?alt=media&token=2f72c288-7bfe-4ca9-8657-2168a00c8d38",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-05%2004-14-56%200893.jpg?alt=media&token=4f9e94d1-84ae-443f-812a-88006235996c",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-05%2007-57-09%200900.jpg?alt=media&token=201d165f-c66d-4042-9182-36be47c23e46",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-05%2012-09-57%200895.jpg?alt=media&token=1daf4ba0-d3ac-4770-a28e-563fd090d34f",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0707%2F22-07-05%2013-32-09%200896.jpg?alt=media&token=b67fe17e-a351-49ba-80a5-811431a19ecb",
		},
		],
		items18_1: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0625%2F22-06-25%2006-33-08%200808.jpg?alt=media&token=93bb4eb4-4c17-486c-baf9-0e6728ea07fb",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0625%2F22-06-25%2006-34-33%200817.jpg?alt=media&token=efca2376-b4ca-494b-bf7f-e44728be801b",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0625%2F22-06-25%2012-26-25%200810.jpg?alt=media&token=0fff7ed8-7c3f-446e-a054-0c9564211af2",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2002-21-33%200819.jpg?alt=media&token=fb902f0f-68d1-4200-9848-fcf1c07c37cc",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2002-44-15%200821.jpg?alt=media&token=60fd1ac6-6817-4e7b-a653-2a4f4ab58aa1",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2002-45-42%200823.jpg?alt=media&token=7c4af940-e72c-497a-84ae-bde09dfe7fb8",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2002-49-05%200815.jpg?alt=media&token=87a60bc0-3a79-4c97-8b01-c6eae5e421e8",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2007-12-05%200816.jpg?alt=media&token=7c622281-1f46-4182-9aee-2fd68071629e",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2008-41-58%200818.jpg?alt=media&token=134a9a86-0ee7-4823-a640-68d7a3b772ad",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2012-15-54%200824.jpg?alt=media&token=17214c67-20c4-4bb1-8dc5-0bdb930c1942",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2012-16-16%200825.jpg?alt=media&token=f9281f9a-da39-4b5a-9d39-5f62b2f83236",
		},
		],
		items18_2: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2010-41-02%200822.jpg?alt=media&token=3f55a2da-93ab-458d-8246-740f5c847f6c",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2010-41-01%200820.jpg?alt=media&token=0b37165a-b52a-4d53-84b6-4930ea64c3ba",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2010-41-02%200822.jpg?alt=media&token=3f55a2da-93ab-458d-8246-740f5c847f6c",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2010-51-43%200809.jpg?alt=media&token=75c3bd5c-66a6-4aaa-9c04-e0e3f0bfca7b",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2010-51-43%200811.jpg?alt=media&token=cb081d98-58e8-45fb-904c-80857b372ee6",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0626%2F22-06-26%2010-51-43%200813.jpg?alt=media&token=0059a948-6d1e-4ce9-ac1a-5619c06ecbd9",
		},
		],
		items15: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0623%2F22-06-23%2019-30-53%200774.jpg?alt=media&token=0bfd61de-550b-449a-81c3-bdd6c86729a5",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0623%2F22-06-23%2019-34-51%200776.jpg?alt=media&token=99d6cc60-2798-4ace-8ef4-c0f36072863f",
		},
		],
		items13: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2007-14-30%200748.jpg?alt=media&token=87bbf3b1-29d2-4fd5-b87b-668551009689",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2008-36-10%200744.jpg?alt=media&token=b83768ae-aaea-4584-94b1-2a88546ec604",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2008-42-26%200745.jpg?alt=media&token=0f5e57a5-22e2-4cea-b357-21f55d0b749d",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2008-46-19%200742.jpg?alt=media&token=e4a1f10d-34d1-4911-877f-fd2ca6d55f68",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2009-02-46%200743.jpg?alt=media&token=dbc60947-fbb3-4e34-b0ae-50dc86b1c4a1",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2010-23-18%200749.jpg?alt=media&token=05b79385-8db7-4366-94f6-24ab45923fa6",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2010-34-19%200747.jpg?alt=media&token=73d7ce88-1eab-41af-98d4-32f2a6ee961f",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2012-07-38%200750.jpg?alt=media&token=245e04b2-8ad7-4009-b12b-ca6938174c7c",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2012-07-58%200746.jpg?alt=media&token=542f9a1b-75ef-47b3-9c51-7d280dac27f1",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0621%2F22-06-21%2015-22-53%200751.jpg?alt=media&token=0e0e05e9-10c7-4297-8a5f-7e275eb9f447",
		},
		],
		items9: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0617%2F22-06-16%2020-52-31%200721.jpg?alt=media&token=2e28e5ac-5623-46b2-9bd3-f7b70e2cee24",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0617%2F22-06-16%2020-54-01%200720.jpg?alt=media&token=55381eda-f85c-4bbc-8274-ab8afae47d0a",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0617%2F22-06-17%2005-14-02%200722.jpg?alt=media&token=6d73082e-1ca8-409a-87cd-64cc183d7850",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0617%2F22-06-17%2007-07-23%200719.jpg?alt=media&token=315b9796-c7a7-432b-b7d4-1c5b35ed42ec",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0617%2F22-06-17%2008-09-05%200716.jpg?alt=media&token=396df464-49db-477b-975c-3d2038e02106",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0617%2F22-06-17%2012-36-42%200718.jpg?alt=media&token=952b1591-0da4-44b1-b97c-6f6deaee4305",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0617%2F22-06-17%2019-33-39%200715.jpg?alt=media&token=8dc87e8f-0757-426c-8684-e7cd48c46e86",
		},
		],
		items7: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2004-53-29%200671.jpg?alt=media&token=d41310c3-d0e9-4eea-9046-18b274d507f5",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2005-03-03%200666.jpg?alt=media&token=eac30290-9193-4e0e-afb5-131d49644ce9",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2005-03-22%200667.jpg?alt=media&token=1db8e8f8-e172-4e4e-8b74-00f501491486",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2007-29-05%200668.jpg?alt=media&token=8970a46d-06f9-4c83-8595-65da5e0415e7",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2007-29-24%200665.jpg?alt=media&token=0827d921-151d-4ca0-a7cc-0d07238d5bb7",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2012-25-47%200669.jpg?alt=media&token=b7e7a52c-761a-4319-ac36-99fb71f28859",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2019-03-57%200672.jpg?alt=media&token=885496ba-0976-41c4-b5a5-bbf199f26f5a",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0615%2F22-06-15%2019-04-27%200673.jpg?alt=media&token=9d2d71c1-e555-4def-8117-864e3607e431",
		},
		],
		items3: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0611%2F22-06-11%2002-06-48%200627.jpg?alt=media&token=43351580-a3ed-457e-9d63-67638bd17157",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0611%2F22-06-11%2007-38-05%200630.jpg?alt=media&token=e5f56c19-dde5-4a15-9dcc-c459d291561f",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0611%2F22-06-11%2007-39-01%200631.jpg?alt=media&token=5494a5da-37d7-4f0a-8c98-ebfea0b57b0f",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0611%2F22-06-11%2007-42-17%200633.jpg?alt=media&token=3318c579-e978-4d25-bd3f-c755aa4858d5",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0611%2F22-06-11%2014-30-33%200634.jpg?alt=media&token=dd607daa-655b-403d-8187-7a1978c91faa",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0611%2F22-06-11%2014-31-03%200624.jpg?alt=media&token=0d076dbf-14f9-48c6-9b6b-5ad1673549a5",
		},
		],
		items2: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0610%2F22-06-10%2003-39-26%200626.jpg?alt=media&token=8869f15a-ddf3-49e1-9998-f353998998d6",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0610%2F22-06-10%2010-46-14%200625.jpg?alt=media&token=d7f27559-382c-4454-b3b2-109050d4e1f5",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0610%2F22-06-10%2012-02-56%200629.jpg?alt=media&token=ebf66cb2-c560-4231-9a7d-c8a098e443e6",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0610%2F22-06-10%2013-33-15%200628.jpg?alt=media&token=07949191-dd17-488d-af3a-dd4de9b69f69",
		},
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0610%2F22-06-10%2013-33-32%200632.jpg?alt=media&token=4b3557c2-e513-4bdb-92cd-7b2640bd1725",
		},
		],
		items1: [
		{
			src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0609%2F22-06-09%2011-14-52%200599.jpg?alt=media&token=a6b27574-f4c6-423d-8536-7514dc122212",
			},
			{
				src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0609%2F22-06-09%2019-48-45%200600.jpg?alt=media&token=80d1f2b5-3f6f-44ee-9f58-4d92a1e965fd",
			},
			{
				src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0609%2F22-06-09%2019-49-01%200601.jpg?alt=media&token=f1ea54e3-b765-4a12-9033-9b016d7261d3",
			},
			],
			items0: [
			{
				src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0608%2F22-06-08%2000-35-27%200596.jpg?alt=media&token=60d85ad3-7814-4082-b255-b5c1596aef03",
			},
			{
				src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0608%2F22-06-08%2000-46-36%200595.jpg?alt=media&token=3d8a6002-cd45-470b-912d-7e07ce510e4b",
				},
				{
					src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0608%2F22-06-08%2009-09-10%200597.jpg?alt=media&token=fe5fdf31-9cd8-4b14-bac2-0b5c911b19b1",
				},
				{
					src: "https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Ftour%2F0608%2F22-06-08%2020-48-41%200598.jpg?alt=media&token=a207142a-8dd7-4755-898b-a1a6de92e08f",
				},
				]
			};
			},
		};
</script>
		
<style>
.v-window__prev {
	top: calc(50%);
}
.v-window__next {
	top: calc(50%);
}
</style>
		