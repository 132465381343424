<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="2" class="sidebar-content"></v-col
      ><v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <h1 class="font-weight-light text-center my-5">Seekajaks und Paddel</h1>
        <h2 class="font-weight-regular my-3">Seekajak</h2>
        <p>
          Die Auswahl an Seekajaks ist sehr groß und so war die Suche nach
          einem für unser Vorhaben geeigneten Kajak gar nicht so einfach.
          Große Unterschiede in Material, Form und Länge sowie Volumen setzen
          einige Überlegungen vor dem Kauf voraus, denn je nach Vorhaben eignet
          sich das eine oder das andere besser. Schnell war uns klar, dass Boote
          aus PE, aufgrund des hohen Gewichts und der "Klobigkeit", für uns
          nicht in Frage kommen. Thorin hat schon häufiger Boote von Lettmann
          ausprobiert und für ihn war daher zumindest schon einmal der
          Hersteller entschieden. Beim Probepaddeln von einigen Modellen
          schieden wieder einige aus (Adria und Biskaya) und so blieben nur noch
          der Skinner und der Skagerrak übrig und letztlich wurde es dann der
          Skagerrak in MV.
        </p>
        <p>
          Tina hat etwas länger für die Entscheidung gebraucht, da sie anfangs
          in vielen Booten starke Knieschmerzen hatte. Nach einiger Zeit haben
          diese sich jedoch gegeben (es lag weniger am Boot als vielmehr an der
          ungewohnten Sitzposition). Da Tina gerne ein gebrauchtes Boot kaufen
          wollte bzw. sie sich einen engeren Preisrahmen gesetzt hatte, war hier
          die Wahl des Bootes zusätzlich eingeschränkt. Beim Bootskauf ist es
          unerlässlich Probefahrten zu machen, was bei gebrauchten Booten oft
          schwierig zu realisieren ist. In unserem heimischen Kanuverein war die
          Auswahl an tauglichen Booten begrenzt und so sind wir für Testfahrten
          an den Bodensee gefahren. Hier musste sie sich nur kurz in den Norse
          Freya setzten um gleich zu merken, dass dieses Boot für sie nicht
          passt. Nach einer längeren Testfahrt mit dem Zegul Arrow Play kam
          dieser direkt in die engere Auswahl. Ein weiterer Kandidat war der
          Lettmann Biskaya. Nun hing alles vom Gebrauchtbootemarkt ab. Nach
          nicht allzu langer Zeit gab es ein unschlagbares Angebot für einen
          jungen Biskaya und Tina nannte das Boot ihr Eigen!
        </p>
        <v-card class="mx-auto my-7" outlined>
          <v-img
            height="66vH"
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fkayaks.jpg?alt=media&token=7356754e-4ce6-48d5-90c5-17fd5531a577"
          ></v-img>
          <v-card-subtitle>
            Unsere beiden Boote einträchtig nebeneinander auf
            Rügen.</v-card-subtitle
          >
        </v-card>
        <p>
          Unsere Boote sind vollwertige Seekajaks und für lange Reisen bis hin
          zu Expeditionen auf dem Meer ausgelegt. Hierbei ist, neben viel
          Stauraum, vor allem eine gute Stabilität und eine hohe Geschwindigkeit
          auf dem Wasser wichtig. Stabilität gibt vor allem die Breite eines
          Bootes, Geschwindigkeit die Länge, aber beide Faktoren beeinflussen
          den jeweils anderen Faktor. Ein gutes Seekajak muss also einen
          Kompromiss zwischen Länge und Breite darstellen, soll es schnell und
          sicher fahren. Der Skagerrak ist 5,55 m lang und 58 cm breit. Damit
          ist es schon ein ziemlich breites Seekajak, welches aufgrund der Länge
          jedoch trotzdem schnell ist. Der Biskaya ist hingegen 5,35 m lang und
          54 cm breit und trotz des Unterschiedes sind beide Boote in etwa
          gleich schnell. Die Grundausstattung der Lettmann Boote beinhaltet
          zwei große Gepäckräume, die durch Schottwände voneinander getrennt
          sind. Auf Wunsch kann jedoch der hintere Stauraum geteilt werden,
          sodass sich eine zusätzliche Luke hinter dem Cockpit ergibt. Diese
          eignet sich sehr gut für das Verstauen von Essen und Equipment,
          welches schnell erreicht werden muss. Im Skagerrak wurde die optionale
          Luke eingebaut, wodurch Thorin einen Großteil des Essens
          transportieren wird. Da Tinas Boot keine zusätzliche Schottwand hat,
          ist das hintere Staufach deutlich länger, wodurch sich besser lange
          Ausrüstungsgegenstände wie der Bootswagen oder das Stativ verstauen
          lassen. Das optionale Fach vor der Sitzluke ist eigentlich nicht
          optional, da dieses sehr praktisch ist für Kleinigkeiten wie
          Sonnenschutz, Handy oder den Keks für Zwischendurch.
        </p>
        <h2 class="font-weight-regular my-3">Paddel</h2>
        <p>
          Fast genauso wichtig wie das Kajak ist das Paddel und auch hier ist
          die Auswahl groß. Im anfänglichen Enthusiasmus über das neue Boot hat
          Thorin sich ein günstiges Carbon Paddel gekauft (weil halt Carbon!).
          Relativ schnell wurde uns dann doch wieder der alte Spruch ins
          Gedächtnis gerufen "wer billig kauft, kauft zweimal". Das günstige
          Paddel war zu weich, um viel Druck aufbauen zu können, und wenige
          Grundberührungen haben schon ausgereicht, um die Kannten zu
          beschädigen. Etwas Neues musste her! Mehr noch als bei Booten führt
          bei Paddeln in der High-End Liga kaum ein Weg an Lettmann vorbei. Und
          wenn man so ein Paddel einmal in der Hand gehabt hat, weiß man einfach
          was man hat! Tina hat sich für ein Ergonom ProSea L entschieden und
          Thorin für das Liberty in Größe L. Es gibt noch ein weiteres Paddel...
          Denn so unverwüstlich die Lettmann Paddel auch sind, alles kann kaputt
          gehen. Und sich mitten auf offener See mit einem kaputten oder gar
          verlorenen Paddel wieder zu finden, kann sich schnell zu einem Alptraum
          entwickeln. Daher sollte IMMER ein Ersatzpaddel mit an Bord sein. Da
          Thorin gerne Neues (bzw. Altes) ausprobiert, wollte er unbedingt ein
          Grönlandpaddel mitnehmen. Diese sind im Gegensatz zum normalen
          (Euro-)Paddel viel schmaler (ca. 8 cm) und sind dadurch "weicher" zu
          fahren. Dadurch lassen sich Belastungsschmerzen in Schulter und
          Handgelenk besser vermeiden. Diese traditionellen Paddel der
          grönländischen Ureinwohner lassen sich wunderbar aus Holz bauen,
          allerdings sind diese dann oft nicht teilbar. Da wir aber ein
          teilbares brauchten, um es auf dem Achterdeck verstauen zu können,
          haben wir uns für das teilbare Gearlap Akiak aus Carbon entschieden.
        </p>
        <v-card class="mx-auto my-7" outlined>
          <v-img
            height="80vH"
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fgroenland_paddel.jpg?alt=media&token=10f93ce1-f7a8-409d-a60a-09e1adc18c3a"
          ></v-img>
          <v-card-subtitle>
            Das Gearlab Akiak auf dem Skagerrak.</v-card-subtitle
          >
        </v-card> </v-col
      ><v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
