import Vue from "vue";
import VueRouter from "vue-router";
import AppHome from "../views/AppHome.vue";
import AppKarte from "../views/AppKarte.vue";
import AppKayak from "../views/AppKayak.vue";
import AppCamp from "../views/AppCamp.vue";
import AppNavigation from "../views/AppNavigation.vue";
import AppTechnik from "../views/AppTechnik.vue";
import AppBootsequ from "../views/AppBootsequ.vue";
import AppBlog from "../views/AppBlog.vue";
import AppVorbereitung from "../views/AppVorbereitung.vue";
import AppStatistiken from "../views/AppStatistiken.vue";
import AppImpressum from "../views/AppImpressum.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: AppHome,
  },
  {
    path: "/karte",
    name: "Karte",
    component: AppKarte,
  },
  {
    path: "/kayak",
    name: "Kayak",
    component: AppKayak,
  },
  {
    path: "/ausruestung/camping",
    name: "Camp",
    component: AppCamp,
  },
  {
    path: "/ausruestung/navigation",
    name: "Navigation",
    component: AppNavigation,
  },
  {
    path: "/ausruestung/technik",
    name: "Technik",
    component: AppTechnik,
  },
  {
    path: "/ausruestung/bootsausruestung",
    name: "Bootsausrüstung",
    component: AppBootsequ,
  },
  {
    path: "/blog/logbuch",
    name: "Logbuch",
    component: AppBlog,
  },
  {
    path: "/blog/vorbereitung",
    name: "Vorbereitung",
    component: AppVorbereitung,
  },
  {
    path: "/statistiken",
    name: "Statistiken",
    component: AppStatistiken,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: AppImpressum,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
