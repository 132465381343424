<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="2" class="sidebar-content"></v-col
      ><v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <v-img
          class="my-2 rounded"
          src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fcover_32.png?alt=media&token=a0e3a9cc-283e-4d6f-bdac-706b1b045ffd"
        ></v-img>
        <h1 class="font-weight-light text-center my-5">Willkommen</h1>
<!--        <h1 class="font-weight-light text-center my-5">
          Unterwegs seit {{ elapsed }}
        </h1>
-->
        <p>
          Am 8. Juni fuhren wir mit der Fähre von Travemünde nach Trelleborg und mit dem Verlassen Deutschlands begann unsere Reise im Norden Europas mit dem Ziel, den Bottnischen Meeresbusen mit Seekajaks zu umpaddeln. Nach 77 Tagen, 2008 km und unzählbar vielen Paddelschlägen haben wir am 24. August wieder unseren Startpunkt erreicht.
        </p>
        <div class="my-7">
          <youtube fitParent video-id="l4NB-WlRsz0" ref="youtube"></youtube>
        </div>
        <v-container class="grey lighten-5" style="padding: 0">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-md-1 py-1">
              <v-card to="/karte" class="mx-auto" outlined>
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2FKarte_Aland_webseite_small.png?alt=media&token=35ad8c77-4ebb-4594-be0c-3db01a73d6bd"
                ></v-img>
                <v-card-title>Zur Karte</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-1 py-1">
              <v-card to="/kayak" class="mx-auto" outlined>
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fkayaks_cards.jpg?alt=media&token=aa0e76b2-f349-4710-9aa6-49e4708ded98"
                ></v-img>
                <v-card-title>Unsere Kajaks</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pr-md-1 py-1">
              <v-card to="/ausruestung/camping" class="mx-auto" outlined>
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fausruestung.jpg?alt=media&token=ae2afdb9-0853-4f33-81ba-c8c2367c9ea2"
                ></v-img>
                <v-card-title> Unsere Ausrüstung </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-1 py-1">
              <v-card to="/blog/logbuch" class="mx-auto" outlined>
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Flogbuch.jpg?alt=media&token=ca7546d6-644f-4e89-a1d4-f3048f95d67a"
                ></v-img>
                <v-card-title>Zum Logbuch </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <h1 class="font-weight-light text-center my-5">Über uns</h1>
        <p>
          Wir sind Tina und Thorin und wollen nun, nach mittlerweile über drei
          Jahren im Beruf und einigen Jahren mehr im Studium, unserer
          Outdoorleidenschaft für einen längeren Zeitraum nachgehen. Bisweilen
          haben wir schon einige Urlaube zu Fuß in den Alpen oder auf dem
          Fahrrad durch Frankreich gemacht, aber das Paddeln wird eine teilweise
          ganz neue Erfahrung werden. Thorin hat mit seinen Eltern schon einige
          Touren mit dem Canadier gemacht, aber für Tina wird das Seekayak ein
          ganz neues und unbekanntes Fortbewegungsmittel sein.
        </p>
        <p>
          Wir lieben das einfache Leben draußen und so ist für uns schon immer
          klar gewesen, dass richtige Erholung nur in der Natur stattfinden
          kann. Wir freuen uns darauf jeden Abend an einem anderen Ort zu sein
          und morgens mit der Sonne aufwachen zu können.
        </p>
        <v-img
          class="mb-4"
          src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fueber_uns2.jpg?alt=media&token=3d36e4ca-f812-48e0-a564-e8533c206b5f"
        ></v-img> </v-col
      ><v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
const elapsed = elapsedTime();
export default {
  data() {
    return { elapsed };
  },
};
function elapsedTime() {
  let start = new Date(2022, 5, 9);
  let end = Date.now();
  return Math.ceil((end - start) / 1000 / 86400) + " Tagen";
}
</script>
<!--
<script type="text/javascript">
let start = new Date(2022, 5, 9);
let end = Date.now();
let elapsed = Math.ceil((end - start) / 1000 / 86400);
let e = document.querySelector("elapsed");
if (e) {
  e.innerHTML = "Unterwegs seit " + elapsed + " Tagen";
} else {
  console.log("Unterwegs seit " + elapsed + " Tagen");
}
</script>
-->
