<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="2" class="sidebar-content"></v-col
      ><v-col cols="12" md="8" class="main-content rounded-lg pa-2 pa-md-6">
        <h1 class="font-weight-light text-center my-5">Technik</h1>
        <h2 class="font-weight-regular my-3">Foto und Video</h2>
        <p>
          Um die schönen Augenblicke, die wir auf dieser Reise erleben werden,
          auch entsprechend festhalten zu können werden wir etwas Fotoausrüstung
          mit ins Boot nehmen. Für Kamera, Actioncam und Drohne werden wir
          allerdings auch Strom benötigen, welchen wir möglichst selbst erzeugen
          wollen und der auch gespeichert werden muss.
        </p>
        <h3 class="font-weight-regular my-2">Sony Alpha 6000</h3>

        <p>
          Schon vor dem letzten Urlaub hat sich Thorin die Systemkamera Sony
          Alpha 6000 gekauft, welche sich beim Paddeln aufgrund der geringen
          Größe bewährt hat. Mit den Wechselobjektiven ist sie auch sehr
          vielseitig. An Objektiven haben wir ein 12 mm F2 Objektiv für
          Landschafts- und Nachtaufnahmen sowie ein 18-200 mm Allroundobjektiv
          mit dabei. Vor allem für Dämmerungs- und Nachtaufnahmen muss natürlich
          auch ein Stativ mit an Bord sein. Bei Stativen herrscht das ewige
          Dilemma, dass man sich zwischen Stabilität, Größe und Packmaß
          entscheiden muss. Da für uns diesmal vor allem ein geringes Packmaß
          wichtig war und das Spektiv diesmal zuhause bleibt, nehmen wir ein
          leichtes Carbon Stativ von Sirui mit.
        </p>
        <h3 class="font-weight-regular my-2">DJI Osmo Action</h3>

        <p>
          Für die Dokumentation der Paddelei haben wir uns eine Actioncam
          zugelegt, welche fest auf dem Boot angebracht werden kann. Da diese
          auch wasserdicht ist, können wir damit auch durch schwere See fahren
          und epische Aufnahmen machen.
        </p>
        <h3 class="font-weight-regular my-2">DJI Mini 2</h3>

        <p>
          Für schöne Aufnahmen von oben oder um die Schlafplatzsuche vom Wasser
          zu erleichtern haben wir auch eine kleine Drohne dabei. Die Wahl war
          hier sehr leicht, da sich Tina beruflich viel mit Drohnen beschäftigt
          und sie daher sehr vertraut mit den DJI Drohnen ist. Die DJI Mini 2
          ist außerdem sehr klein und kompakt und lässt sich so gut verstauen.
        </p>
        <p>
          Um unsere gesamten Aufnahmen zu speichern, müssen wir jede Menge
          Speicherkapazität dabeihaben. Aktuell haben wir 480 GB Speicher dabei.
          Und falls das nicht reichen sollte, Schweden und Finnland sind ja
          nicht aus der Welt.
        </p>
        <h2 class="font-weight-regular my-3">Stromversorgung</h2>
        <h3 class="font-weight-regular my-2">Solarpanel</h3>
        <p>
          Um unsere ganzen technischen Gerätschaften am Laufen zu halten,
          brauchen wir natürlich Strom. Die beste Art und Weise unterwegs Strom
          zu erzeugen ist natürlich über die Sonne. Da wir das Solarpanel auf
          dem Boot befestigen wollen, um auch beim Paddeln Energie zu erzeugen,
          muss es natürlich auch wasserdicht sein. Daher haben wir uns für das
          Sistech Solarflex 5 V/10 W mit passender 10.000 mAh Powerbank
          entschieden. Das Panel ist so dick wie eine LKW-Plane und
          zusammengefaltet so groß wie ein DIN-A4-Blatt. Die Anschlüsse werden
          mit wasserdichten Micro-USB-Kabeln verbunden, was sich dadurch perfekt
          für unsere Zwecke eignet.
        </p>

        <v-card class="mx-auto my-7" outlined>
          <v-img
            height="66vH"
            src="https://firebasestorage.googleapis.com/v0/b/paddling-beyond.appspot.com/o/img%2Fsolar_panel.jpg?alt=media&token=f3b9c77d-5fd6-4106-87af-009e3214d96a"
          ></v-img>
          <v-card-subtitle>
            Solarpanel von Sistech auf dem Kajak angebracht.
          </v-card-subtitle>
        </v-card>

        <h3 class="font-weight-regular my-2">Powerbank</h3>
        <p>
          Auf unserer Reise werden wir vermutlich nur selten einen Campingplatz
          anlaufen, weshalb wir nicht alleine auf das Solarpanel für die
          Stromversorgung setzen können. Deshalb haben wir mehrere Powerbanks
          dabei, die wir über die Powerbank des Solarpanels laden können.
          Insgesamt kommen wir auf eine Kapazität von etwa 50.000 mAh. Wieviel
          Strom wir im Endeffekt benötigen werden, lässt sich Vorhinein nur
          schwer abschätzen und wir werden vermutlich die ersten Tage der Reise
          nutzen, um hier ein besseren Überblick zu bekommen. Wenn alle Stricke
          aber reißen sollten, ist es immer noch möglich die Akkus der Drohne zum
          Laden von Verbrauchern zu nutzen.
        </p>

        <!-- Buttons -->
        <v-row class="mt-5 mb-1" align="center" justify="space-around">
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/navigation"
          >
            <v-icon>mdi-arrow-left-bold</v-icon></v-btn
          >
          <v-btn
            color="primary"
            fab
            small
            elevation="2"
            to="/ausruestung/bootsausruestung"
          >
            <v-icon>mdi-arrow-right-bold</v-icon></v-btn
          >
        </v-row></v-col
      ><v-col cols="0" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
