<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col
        sm="6"
        md="5"
        lg="4"
        xl="3"
        class="fill-parent-height"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <CardsDesktop
          :gpxCollection="gpxCollection_filtered"
          :gpx_id.sync="gpx_id"
        />
      </v-col>
      <v-col sm="6" md="7" lg="8" xl="9" :class="calc_map_class">
        <v-progress-circular
          size="50"
          width="6"
          v-if="!loaded"
          indeterminate
          style="z-index: 1"
          class="loadingIcon"
          color="primary"
        ></v-progress-circular>
        <l-map
          ref="Map"
          style="z-index: 0"
          :zoom="zoom"
          :center="center"
          :options="{ zoomControl: false }"
        >
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <GpxRoute
            v-for="(gpx, index) in gpxCollection"
            :key="index"
            :gpx="gpx"
            :loaded="loaded"
            @recieved="addGPXInfo"
          />
          <l-control-zoom
            v-if="$vuetify.breakpoint.smAndUp"
            position="bottomright"
          ></l-control-zoom>
          <l-control
            v-if="$vuetify.breakpoint.xsOnly"
            position="bottomright"
            class="leaflet-bar card-menu"
          >
            <button @click="toggleCards">
              <v-icon class="ma-1"> mdi-routes </v-icon>
            </button>
          </l-control>
        </l-map>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="showCards" class="grey lighten-4 cards-mobile"
      ><v-col cols="12">
        <CardsMobile
          :gpxCollection="gpxCollection_filtered"
          :gpx_id.sync="gpx_id"
        /> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import GpxRoute from "@/components/map/GpxRoute.vue";
import CardsDesktop from "@/components/map/CardsDesktop.vue";
import CardsMobile from "@/components/map/CardsMobile.vue";
import { LMap, LTileLayer, LControlZoom, LControl } from "vue2-leaflet";
import "leaflet-gpx";
import "leaflet/dist/leaflet.css";

import { db } from "../main";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  name: "AppKarte",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LControl,
    GpxRoute,
    CardsDesktop,
    CardsMobile,
  },
  data() {
    return {
      gpxCollection: [],
      data: [],
      map: {},
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 5,
      center: [62, 20],
      markerLatLng: [51.504, -0.159],
      loadingValue: 0,
      loaded: false,
      showCards: false,
      gpx_id: 0,
    };
  },
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    this.$nextTick(() => {
      this.map = this.$refs.Map.mapObject;
    });
    db.collection("gpx")
      .get()
      .then((querySnapshot) => {
        const storage = getStorage();
        const documents = querySnapshot.docs.map((doc) => doc.data());

        this.gpxCollection = documents;
        let that = this;
        let counter = 0;
        let length = documents.length;
        let increment = 100 / length;

        documents.forEach(function (document, index) {
          getDownloadURL(ref(storage, document.gpx_url))
            .then((url) => {
              const xhr = new XMLHttpRequest();
              xhr.responseType = "text";
              xhr.open("GET", url);
              xhr.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                  if (this.status === 200) {
                    that.gpxCollection[index].gpxFile = xhr.response;
                    counter++;
                    that.loadingValue += increment;
                    if (counter === length) {
                      that.loaded = true;
                    }
                  } else {
                    console.log(this.status, this.statusText);
                  }
                }
              };
              xhr.send();
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
  },
  methods: {
    addGPXInfo(info) {
      let index = info[0];
      this.gpxCollection[index].GPXinfo = info[1];
    },
    toggleCards() {
      this.showCards = !this.showCards;
    },
    set_gpx_id(gpx_id) {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.showCards = true;
      }
//      console.log("#" + "gpx_track_id_" + gpx_id);
//      document.getElementById("gpx_track_id_" + gpx_id).scrollIntoView({
//        behavior: "smooth",
//      });

      //carousel-items (Cardsmobile.vue) don't have a #gpx_track_id -> document.getElementById is undefined
      //we should check that

      var e = document.getElementById("gpx_track_id_" + gpx_id);
      if (e) {
        e.scrollIntoView({behavior: "smooth", });
        //The meaning of the following instruction is unclear
        this.gpx_id = parseInt(gpx_id);
      } else {
        //Important to focus on the right carousel item
        this.gpx_id = parseInt(gpx_id-1);
      }
    },
    getWeatherIcon(weather) {
      let icon = null;
      if (weather == "sunny") {
        icon = "mdi-white-balance-sunny";
      } else if (weather == "rainy") {
        icon = "mdi-weather-pouring";
      } else if (weather == "cloudy") {
        icon = "mdi-weather-cloudy";
      } else if (weather == "storm") {
        icon = "mdi-weather-windy-variant";
      } else if (weather == "snow") {
        icon = "mdi-snowflake";
      } else if (weather == "partly-cloudy") {
        icon = "mdi-weather-partly-cloudy";
      } else if (weather == "partly-rainy") {
        icon = "mdi-weather-partly-rainy";
      }
      return icon;
    },
    getWeatherDescription(weather) {
      let description = null;
      if (weather == "sunny") {
        description = "Sonnig";
      } else if (weather == "rainy") {
        description = "Regnerisch";
      } else if (weather == "cloudy") {
        description = "Bewölkt";
      } else if (weather == "storm") {
        description = "Stürmisch";
      } else if (weather == "snow") {
        description = "Schnee";
      } else if (weather == "partly-cloudy") {
        description = "leicht bewölkt";
      } else if (weather == "partly-rainy") {
        description = "leichter Regen";
      }
      return description;
    },
    getStayTypeIcon(type) {
      let icon = null;
      if (type == "zelten" || type == "wildzelten") {
        icon = "mdi-tent";
      } else if (type == "hotel") {
        icon = "mdi-bed";
      }
      return icon;
    },
    getStayTypeDescription(type) {
      let description = null;
      if (type == "zelten" || type == "wildzelten") {
        description = "Zelten";
      } else if (type == "hotel") {
        description = "Hotel";
      }
      return description;
    },
    getTrackDescription(dateString, type) {
      let date = new Date(dateString);
      let description = date.toLocaleDateString();
      if (type == "rest-day") {
        description += " - Ruhetag";
      } else if (type == "flight") {
        description += " - Flug";
      } else if (type == "train") {
        description += " - Zugverbindung";
      } else if (type == "ferry") {
        description += " - Fähre";
      }
      return description;
    },
    getDistance(item) {
      return (item.distance >= 0 ? item.distance : Math.round(item.GPXinfo.distance / 100) / 10);
    },
    getMovingTime(item) {
      return (item.movingtime ? item.movingtime : this.msToHMS(item.GPXinfo.moving_time));
    },
    msToHMS(duration) {
      let minutes = parseInt((duration / (1000 * 60)) % 60);
      let hours = parseInt((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;

      return hours + ":" + minutes;
    },
    goToBlog(id) {
      this.$router.push({ path: "/blog/logbuch" });
      console.log(id);
      setTimeout(() => {
        document.getElementById("blog-" + id).scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    },
    zoomToGPX(gpx_id) {
//      this.map = this.$parent.$refs.Map.mapObject;
      let that = this;
      this.map.eachLayer(function (layer) {
        if (layer.layerID == gpx_id) {
          // reset map bounds
          that.map.invalidateSize();
          // try catch in case gpy does´t have bounds
          try {
            that.map.flyToBounds(layer.getBounds());
          } catch {
            let lat = layer._latlng.lat;
            let lng = layer._latlng.lng;
            that.map.flyTo([lat, lng], 13);
          }
        }
      });
    },
  },
  computed: {
    gpxCollection_filtered() {
      return this.gpxCollection.filter((item) => item.show_in_cards === "true");
    },
    calc_map_class() {
      if (this.$vuetify.breakpoint.xsOnly && this.showCards) {
        return "fill-remaining";
      } else if (this.$vuetify.breakpoint.xsOnly && this.showCards == false) {
        return "fill-parent-height-small";
      } else {
        return "fill-parent-height";
      }
    },
  },
};
</script>

<style>
.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
html {
  overflow-y: auto;
}

.fill-parent-height {
  height: calc(100vh - 92px);
  overflow-y: scroll;
}

.fill-parent-height-small {
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100) - 56px);
}

.fill-remaining {
  height: calc((var(--vh, 1vh) * 100) - 276px);
}

.card-menu {
  background-color: #fff;
}

.cards-mobile {
  overflow-y: scroll;
  height: 300px;
}
</style>
