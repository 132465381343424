<template>
  <div style="display: none"></div>
</template>

<script>
import "leaflet-gpx";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "GpxRoute",
  props: {
    gpx: {
      type: Object,
    },
    loaded: {
      type: Boolean,
    },
  },
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.map = this.$parent.$parent.$refs.Map.mapObject;
  },
  watch: {
    loaded() {
      this.addGPXLayer();
    },
  },
  methods: {
    addGPXLayer() {
      this.map.addLayer;
      let map = this.map;
      let that = this;
      let end_icon_url = "";
      if (
        this.$props.gpx.stay_type == "zelten" ||
        this.$props.gpx.stay_type == "wildzelten"
      ) {
        end_icon_url = require("@/assets/map/marker_tent.png");
      } else if (this.$props.gpx.stay_type == "hotel") {
        end_icon_url = require("@/assets/map/marker_bed.png");
      } else if (this.$props.gpx.stay_type == "start") {
        end_icon_url = require("@/assets/map/marker_start.png");
      } else if (this.$props.gpx.stay_type == "zug") {
        end_icon_url = require("@/assets/map/marker_train.png");
      } else if (this.$props.gpx.stay_type == "flug") {
        end_icon_url = require("@/assets/map/marker_plane.png");
      } else if (this.$props.gpx.stay_type == "transit") {
        end_icon_url = "";
      }
      let gpx_color = "";
      let gpx_color_mouseover = "";
      if (
        this.$props.gpx.stay_type == "zug" ||
        this.$props.gpx.stay_type == "flug"
      ) {
        gpx_color = "#ffc98f";
        gpx_color_mouseover = "#ffc98f";
      } else {
        gpx_color = "#0066a2";
        gpx_color_mouseover = "#3293a8";
      }
      let start_icon_url = "";
      if (this.$props.gpx.stay_type == "zug") {
        start_icon_url = require("@/assets/map/marker_train.png");
      } else if (this.$props.gpx.stay_type == "plane") {
        start_icon_url = require("@/assets/map/marker_plane.png");
      }
      if (this.$props.gpx.show_markers == "false") {
        start_icon_url = "";
        end_icon_url = "";
      }
      new L.GPX(this.$props.gpx.gpxFile, {
        async: true,
        max_point_interval: 180000,
        gpx_options: {
          joinTrackSegments: false,
        },
        polyline_options: {
          color: gpx_color,
          weight: 4,
          lineCap: "round",
        },
        marker_options: {
          startIconUrl: start_icon_url,
          endIconUrl: end_icon_url,
          shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
          wptIconUrls: {
            "": end_icon_url,
          },
        },
      })
        .on("loaded", function (e) {
          var gpx = e.target;
          var info = {
            distance: gpx.get_distance(),
            //elevation_gain: gpx.get_elevation_gain(),
            moving_time: gpx.get_moving_time(),
            //total_time: gpx.get_total_time(),
            //moving_speed: gpx.get_moving_speed(),
          };
          var layer = gpx.getLayers()[0];
          layer.on("click", () => {
            that.$parent.$parent.set_gpx_id(that.$props.gpx.id);
          });
          layer.unbindPopup();
          layer.layerID = that.$props.gpx.id;
          that.emitGPXInfo(info);
        })
        .on("mouseover", function (e) {
          e.target.setStyle({ color: gpx_color_mouseover });
        })
        .on("mouseout", function (e) {
          e.target.setStyle({ color: gpx_color });
        })

        .addTo(map);
    },
    emitGPXInfo(value) {
      this.$emit("recieved", [this.$vnode.key, value]);
    },
  },
};
</script>
